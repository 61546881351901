export const queryStringParams = (): string[] => {
  return window.location.search.substring(1).split("&");
};

export const getParameterValue = (param: string): string => {
  const urlVariables = queryStringParams();

  for (let i = 0; i < urlVariables.length; i++) {
    const parameterName = urlVariables[i].split("=");
    if (parameterName[0] === param) {
      return parameterName[1];
    }
  }

  return "";
};
