import type { FC } from "react";
import { useRef } from "react";

import { Avatar, Box, ButtonBase, Hidden, Typography } from "@mui/material";

import { useProfile } from "src/contexts/ProfileContext";
import useAuth from "src/hooks/useAuth";
import useClasses from "src/hooks/useClasses";

const drawerWidth = 240;
const styles = ({ theme }) => ({
  avatar: {
    height: 40,
    width: 40,
    marginRight: theme.spacing(1),
  },
  popover: {
    width: 200,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
});

const Account: FC = () => {
  const classes: any = useClasses(styles);
  const ref = useRef<any>(null);
  const { user } = useAuth();
  const { dispatch } = useProfile();

  return (
    <div data-display-name="Account">
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={() => {
          dispatch({
            type: "SET_OPEN",
            payload: { user, canEdit: true },
          });
        }}
        // @ts-ignore
        ref={ref}
      >
        <Avatar alt="User" className={classes.avatar} src={""} />
        <Hidden xlDown>
          <Typography variant="h6" color="inherit">
            {user.FullName}
          </Typography>
        </Hidden>
      </Box>
    </div>
  );
};

export default Account;
