import type { FC } from "react";
import { useEffect } from "react";

import { Route, Routes } from "react-router-dom";
import i18n from "src/i18n";
import MainLayout from "src/layouts/AuthLayout";
import Crest from "./Crest";
import Forgot from "./Forgot";
import Login from "./Login";

const NotAuthenticatedContent: FC = () => {
  const getLanguage = (value) => {
    if (value === "en" || value === "en-US" || value === "E") return "E";
    if (value === "fr" || value === "fr-CA" || value === "F") return "F";
  };

  useEffect(() => {
    const language = getLanguage(localStorage.getItem("i18nextLng"));
    i18n.changeLanguage(language);
  }, []);

  return (
    <MainLayout data-display-name="NotAuthenticatedContent">
      <Crest>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot" element={<Forgot />} />
          <Route path="*" element={<Login />} />
        </Routes>
      </Crest>
    </MainLayout>
  );
};

export default NotAuthenticatedContent;
