import type { FC, ReactNode } from "react";
import React from "react";
import { Navigate } from "react-router-dom";
import useAuth from "../hooks/useAuth";

interface AuthGuardProps {
  navigateTo?: string;
  children?: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = ({
  navigateTo,
  children,
}: AuthGuardProps) => {
  const { isAuthenticated, logout } = useAuth();

  if (!isAuthenticated) {
    logout();
    return <Navigate to={navigateTo} />;
  }

  return <>{children}</>;
};

export default AuthGuard;
