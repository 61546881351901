import {
  DialogActions,
  Button,
  TextField,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  DialogContentText,
  Autocomplete
} from "@mui/material";
import { Warning } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { snackActions } from "src/utils/SnackbarUtils";
import { useLookups } from "src/contexts/LookupsContext";
import { useProfile } from "src/contexts/ProfileContext";
import { useMemo, useState } from "react";
import useAuth from "src/hooks/useAuth";
import useHotkeys from "src/hooks/useHotkeys";

interface Props {
  open: boolean;
  onClose?: () => void;
}
enum ACTION_TYPES {
  INITIALIZE
}
const UserSettingModal = ({
  open,
  onClose,
}: Props) => {


  const { t } = useTranslation();
  const { user, dispatch, navigationContent } = useAuth() as any;
  const { state: lookupsState } = useLookups();
  const { updateUser } = useProfile();
  const [userdata, setUser] = useState(user);
  const [isDirty, setIsDirty] = useState(false);
  const [highlightedValue, setHighlightedValue] = useState(null);
  const [errors, setErrors] = useState({
    prefixGroup: false
  });
  const handleConfirmAndClose = async () => {
    try {
      const hasErrors = !userdata?.UserPrefixGroup;
      if (hasErrors) {
        setErrors({ prefixGroup: true });
        return;
      }
      if (isDirty) {
        updateUser(userdata);
        dispatch({
          type: ACTION_TYPES.INITIALIZE,
          payload: {
            isAuthenticated: true,
            user: userdata,
            navigationContent
          },
        });
        onClose();
        snackActions.success("Successfully updated user");
      } else { onClose(); }
    } catch (err) {
      snackActions.error("Failed to update user");
    }
  };

  const prefixGroup = useMemo(
    () =>
      lookupsState.prefixGroups?.map((prefixgroup) => ({
        prefixGroupCode: prefixgroup.PrefixGroupCode,
        prefixGroupName: prefixgroup.PrefixGroupName,
      })),
    [lookupsState.prefixGroups]
  );

  const prefixOptions = useMemo(() => {
    const selectedPrefixGroup = lookupsState.prefixGroups.find(
      (x) => x.PrefixGroupCode === userdata.UserPrefixGroup
    );
    if (selectedPrefixGroup) {
      return selectedPrefixGroup.JobPrefix.map((prefix) => ({
        prefixCode: prefix,
        prefixName: `${prefix}`,
      }));
    } else {
      return [];
    }
  }, [userdata.UserPrefixGroup, lookupsState.prefixGroups]);
  useHotkeys("alt+c", () => {
    onClose();
  });

  useHotkeys("alt+x", () => {
    handleConfirmAndClose();
  });
  return (
    <Dialog open={open} onClose={onClose} maxWidth={"xs"} fullWidth>
      <DialogTitle
        variant="h4"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "flex-start",
          fontWeight: "bold",
          color: "gray"
        }}
      >
        {t("common:useroptions:title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText sx={{
          fontWeight: "bold"
        }}>
          {t('common:useroptions:usersettingtitle')}
        </DialogContentText>
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              options={prefixGroup}
              value={
                prefixGroup.filter(
                  (x) => x.prefixGroupCode === userdata?.UserPrefixGroup
                )[0] || ""
              }
              getOptionLabel={(option: any) =>
                option.prefixGroupCode
                  ? `${option.prefixGroupName.split(":")[0]}`
                  : ''
              }
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label={t("common:useroptions:prefixGroup")}
                    required
                    error={errors.prefixGroup}
                    helperText={
                      errors.prefixGroup
                        ? <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "0.25rem",
                          }}
                        >
                          <Warning fontSize="small" />
                          {t("common:useroptions:prefixGroupRequired")}
                        </span>
                        : ""
                    }
                  />
                );
              }}
              onChange={(e: any, value: any) => {
                if (value?.prefixGroupCode !== userdata?.UserPrefixGroup) {
                  setUser({
                    ...userdata,
                    UserPrefixGroup: value?.prefixGroupCode || "",
                    UserSRPrefix: ""
                  });
                  setIsDirty(true);
                }
              }}
              onHighlightChange={(e, value: any) => {
                if (value) {
                  setHighlightedValue({
                    prefixGroupCode: value?.prefixGroupCode,
                  });
                } else {
                  setHighlightedValue(null);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Tab" && highlightedValue) {
                  if (highlightedValue?.prefixGroupCode !== userdata?.UserPrefixGroup) {
                    setUser({
                      ...userdata,
                      UserPrefixGroup: highlightedValue?.prefixGroupCode || "",
                      UserSRPrefix: ""
                    });
                    setIsDirty(true);
                  }
                }
              }}
              clearText={t("common:clear")}
            />
          </Grid>
          <Grid item xs={12}>
            <Autocomplete
              fullWidth
              options={prefixOptions}
              value={
                prefixOptions.filter(
                  (x) => x.prefixCode === userdata?.UserSRPrefix
                )[0] || ""
              }
              getOptionLabel={(option: any) =>
                option.prefixName
                  ? option.prefixName
                  : ""
              }
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    label={t("common:useroptions:prefix")}
                  />
                );
              }}
              onChange={(e: any, value: any) => {
                if (value?.prefixCode !== userdata?.UserSRPrefix) {
                  setUser({
                    ...userdata,
                    UserSRPrefix: value?.prefixCode || ""
                  });
                  setIsDirty(true);
                }
              }}
              onHighlightChange={(e, value: any) => {
                if (value) {
                  setHighlightedValue({
                    prefixCode: value?.prefixCode,
                  });
                } else {
                  setHighlightedValue(null);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Tab" && highlightedValue) {
                  if (highlightedValue?.prefixCode !== userdata?.UserSRPrefix) {
                    setUser({
                      ...userdata,
                      UserSRPrefix: highlightedValue?.prefixCode || ""
                    });
                    setIsDirty(true);
                  }
                }
              }}
              clearText={t("common:clear")}
            />


          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t("common:cancel")}</Button>
        <Button onClick={handleConfirmAndClose} variant="contained">{t("common:applyandclose")}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default UserSettingModal;
