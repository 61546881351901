import ContainerNavigation from "src/Navigation";
import ContainerRoutes from "src/Routes";
import CommonNavigation from "src/CommonNavigation";
import CommonRoutes from "src/CommonRoutes";

const findRoute = () => {
  switch (window.APP_NAME) {
    case "container":
      return ContainerRoutes;
  }

  return CommonRoutes;
};

const findNavigation = () => {
  switch (window.APP_NAME) {
    case "container":
      return ContainerNavigation;
  }

  return CommonNavigation;
};

export { findRoute, findNavigation };
