import { AccountCircle, Lock, Logout, VerifiedUser } from "@mui/icons-material";
import type { ISection } from "src/types/ISection";

const sections: ISection[] = [
  {
    subheader: "ACCOUNT",
    items: [
      {
        title: "Profile",
        href: "/profile",
        icon: AccountCircle,
        hasAccess: true,
      },
      {
        title: "Logout",
        href: "/Logout",
        icon: Logout,
        hasAccess: true,
      },
      {
        title: "Terms & Conditions",
        href: "/terms",
        icon: VerifiedUser,
        hasAccess: true,
      },
      {
        title: "Privacy Policy",
        href: "/privacy",
        icon: Lock,
        hasAccess: true,
      },
    ],
  },
];

export default sections;
