import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

export const format = (date: string | Date | Dayjs, format?: string) => {
  if (dayjs(date).isValid() === false) {
    return "";
  }

  return dayjs(date).format(
    format
      ? format
      : process.env.REACT_APP_DATE_TIME_FORMAT ?? "MMM-DD-YYYY HH:mm"
  );
};

export const getDate = (value): string => {
  const today =
    value === undefined || value === "" ? new Date() : new Date(value);
  const returnDate = `${
    today.getMonth() + 1
  }/${today.getDate()}/${today.getFullYear()}`;
  return returnDate;
};

export const convertLocaleDateTimeToUTC = (date: Date | string): string => {
  return dayjs(date).utc().toDate().toISOString().replace("Z", "");
};

export const convertUTCtoLocaleDateTime = (date: Date | string) => {
  // if data is not valid date, return empty string

  if (dayjs(date).isValid() === false) {
    return "";
  }

  return dayjs(date + "Z")
    .utc()
    .local()
    .toDate();
};
