import { ExpandMore } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import type { FC } from "react";
import React from "react";
import { snackActions } from "../../utils/SnackbarUtils";

interface Props {
  [key: string]: any;
}

const FormikHelper: FC<Props> = ({ errors, values, touched, ...rest }) => {
  return (
    <Box my={2}>
      <Box my={2}>
        <Typography variant="h3">Formik Helper (Debug Mode Only)</Typography>
      </Box>
      <Box mt={1}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>errors</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Alert severity="error">
              {Object.entries(errors).map(([key, value]) => (
                <div key={key}>
                  {key} : {JSON.stringify(value)}
                  <br />
                </div>
              ))}
            </Alert>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>values</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Alert severity="info">
              {Object.entries(values).map(([key, value]) => (
                <div key={key}>
                  {key} : {JSON.stringify(value)} [{typeof value}]<br />
                </div>
              ))}
            </Alert>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMore />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>touched</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Alert severity="info">
              {Object.entries(touched).map(([key, value]) => (
                <div key={key}>
                  {key} : {JSON.stringify(value)} <br />
                </div>
              ))}
            </Alert>
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

FormikHelper.propTypes = {
  className: PropTypes.string,
};

export const submitSuccess = (msg, actions?) => {
  snackActions.success(msg);
  actions?.setStatus({ success: true });
  actions?.setSubmitting(false);
  actions?.setErrors({});
};

export const submitError = (err, actions?, noSnack = false) => {
  console.error(err);
  console.log(`${err} | ${err?.message} | ${err?.response?.data}`);
  actions?.setStatus({ success: false });
  actions?.setErrors({
    submit: err?.response ? err?.response?.data : err,
  });
  actions?.setSubmitting(false);

  if (!noSnack) {
    let out = "";

    try {
      out = JSON.stringify(
        err?.response ? err?.response?.data : err
      ).replaceAll('"', "");
    } catch (err) {}
    if (out) {
      snackActions.error(out);
    }
  }
};

export default FormikHelper;
