import { Alert, Box, Typography } from "@mui/material";
import type { FC } from "react";
import React, { useEffect, useState } from "react";
import useSettings from "src/hooks/useSettings";
import { isObjectEmpty } from "src/utils/object";
import FormikHelper from "./FormikHelper";

interface Props {
  [key: string]: any;
}

const ErrorPanel: FC<Props> = ({
  errors = {},
  values = {},
  touched = {},
  ...rest
}) => {
  const { settings } = useSettings();
  const [initialValues, setInitialValues] = useState(null);

  useEffect(() => {
    if (!initialValues) setInitialValues(values);
  }, [values, initialValues]);

  // https://stackoverflow.com/questions/42503599/how-to-make-javascript-scrollintoview-smooth
  const handlescrollIntoView = (key) => {
    var elmnt = document.getElementsByName(key)[0];

    if (elmnt) elmnt.scrollIntoView({ block: "center", behavior: "smooth" });
  };

  return (
    <>
      {/* <Prompt
        message="You will lose your changes if you leave this page"
        when={
          Object.keys(touched).length > 0 && !_.isEqual(values, initialValues)
        }
      /> */}
      {settings.debug && (
        <FormikHelper errors={errors} values={values} touched={touched} />
      )}
      {!isObjectEmpty(errors) && (
        <Box mb={2}>
          <Alert severity="error" style={{ paddingTop: 0 }}>
            <Typography variant="h6">Please note</Typography>

            <ul style={{ fontSize: 10, wordWrap: "break-word" }}>
              {Object.entries(errors).map(([key, value]) => (
                <li
                  key={key}
                  style={{ cursor: "pointer", overflowWrap: "anywhere" }}
                  onClick={() => handlescrollIntoView(key)}
                >
                  {JSON.stringify(value)?.replaceAll('"', "")}
                </li>
              ))}
            </ul>
          </Alert>
        </Box>
      )}
    </>
  );
};

export default ErrorPanel;
