import React from "react";

export interface InitOptions {
  authContext: React.Context<any>;
  authContextProvider: React.FC<any>;
  renderTopBar?: React.ComponentType<any>;
}

declare global {
  // eslint-disable-next-line no-var
  var BV_APP_OPTIONS: InitOptions;
}

const appConfiguration = {
  init: (options: InitOptions): void => {
    window.BV_APP_OPTIONS = options;
  },
  use: (): InitOptions => window.BV_APP_OPTIONS,
};

export default appConfiguration;
