import { styled } from "@mui/material/styles";
import type { FC, ReactNode } from "react";
import React from "react";
import background from "src/assets/images/Login_background.jpg";

interface MainLayoutProps {
  children?: ReactNode;
}

const Root = styled("div")(({ theme }) => ({
  background: `url(${background})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "center center",
  backgroundAttachment: "fixed",
  // backgroundColor: theme.palette.colors.darkBackground,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const Wrapper = styled("div")(() => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
}));

const ContentContainer = styled("div")(() => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
}));

const Content = styled("div")(() => ({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
}));

const MainLayout: FC<MainLayoutProps> = ({ children }) => {
  return (
    <Root data-display-name="MainLayout">
      <Wrapper>
        <ContentContainer>
          <Content>{children}</Content>
        </ContentContainer>
      </Wrapper>
    </Root>
  );
};

export default MainLayout;
