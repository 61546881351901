import { useSnackbar } from "notistack";
import type { ReactNode } from "react";
import React, { createContext, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import ErrorHandler from "src/components/errorHandler";
import useAuth from "src/hooks/useAuth";

export enum VARIAN_TYPE {
  DEFAULT = "default",
  SUCCESS = "success",
  ERROR = "error",
  WARNING = "warning",
  INFO = "info",
}

const STATUS_CODE_0 = 0;
const STATUS_CODE_401 = 401;
const STATUS_CODE_404 = 404;
const STATUS_CODE_500 = 500;
const STATUS_CODE_400 = 400;
interface UserNotificationContextValue {
  UserNotificationMessage?: (
    defaultMessage: string,
    error: any,
    isError?: boolean
  ) => boolean;
}

const UserNotificationContext = createContext<UserNotificationContextValue>({});
const useUserNotification = (): UserNotificationContextValue =>
  useContext(UserNotificationContext);

interface ProviderProps {
  children: ReactNode;
}

const UserNotificationProvider: React.FC<ProviderProps> = ({
  children,
}: ProviderProps): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [apiErrorMessage, setApiErrorMessage] = useState<string>("");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { logout } = useAuth();

  const setEnqueueSnackbar = (
    error,
    varianType = VARIAN_TYPE.ERROR,
    autoHideDuration = 5000
  ) => {
    enqueueSnackbar(error, {
      variant: varianType,
      autoHideDuration,
    });
  };

  const UserNotificationMessage = (
    defaultMessage: string,
    error?: any | null,
    isError = false
  ): boolean => {
    let remainOpen = true;
    const responseUserNotificationMessage =
      error === null
        ? defaultMessage
        : `${defaultMessage} ${error?.status} - ${error?.statusText}`;
    const responseApiUserNotificationMessage =
      error !== null
        ? error.data !== undefined &&
          error.data.Message !== undefined &&
          error.data.Message !== ""
          ? error.data.Message
          : ""
        : "";

    if (error === null) {
      setEnqueueSnackbar(
        responseUserNotificationMessage,
        isError ? VARIAN_TYPE.ERROR : VARIAN_TYPE.SUCCESS
      );
    } else {
      switch (error.status) {
        case STATUS_CODE_401:
          setEnqueueSnackbar(responseUserNotificationMessage);
          (async () => {
            await logout();
          })();
          remainOpen = false;
          navigate("/");
          break;
        case STATUS_CODE_0:
        case STATUS_CODE_404:
        case STATUS_CODE_400:
        case STATUS_CODE_500:
          if (isError) {
            setEnqueueSnackbar(
              responseUserNotificationMessage,
              isError ? VARIAN_TYPE.ERROR : VARIAN_TYPE.SUCCESS
            );
          } else {
            setErrorMessage(responseUserNotificationMessage);
            setApiErrorMessage(responseApiUserNotificationMessage);
          }
          break;
        default:
          setEnqueueSnackbar(
            responseUserNotificationMessage,
            VARIAN_TYPE.ERROR
          );
          break;
      }
    }

    return remainOpen;
  };

  return (
    <UserNotificationContext.Provider
      value={{
        UserNotificationMessage,
      }}
    >
      {errorMessage !== "" && (
        <ErrorHandler
          message={errorMessage}
          apiMessage={apiErrorMessage}
          setMessage={setErrorMessage}
        />
      )}
      {children}
    </UserNotificationContext.Provider>
  );
};

export { UserNotificationProvider, useUserNotification };
