import type { IContent } from "src/types/IContent";
import type { IUser } from "src/types/IUser";

export interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: IUser | null;
  navigationContent: IContent | null;
}

export interface AuthContextValue extends AuthState {
  login: (email: string, password: string, region: string) => Promise<void>;
  logout: () => void;
}

export const initialAuthState: AuthContextValue = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  navigationContent: null,
  login: () => Promise.resolve(),
  logout: () => {},
};

export interface ResponseData {
  ResponseData?: any;
}
