import {
  HotkeyCallback,
  Keys,
  useHotkeys as useHotkeysHook,
} from "react-hotkeys-hook";
import { OptionsOrDependencyArray } from "react-hotkeys-hook/dist/types";

const useHotkeys = (
  hotkeys: Keys,
  callback: HotkeyCallback,
  options?: OptionsOrDependencyArray
) => {
  useHotkeysHook(hotkeys, callback, {
    ...options,
    enableOnFormTags: true,
  });
};

export default useHotkeys;
