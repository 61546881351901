import React, { useState } from "react";
import {
  Button,
  Box,
  Divider,
  Typography,
  useTheme,
  useMediaQuery,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import SidebarPanelLabel from "src/components/sidebarPanelLabel";

interface ErrorHandlerProps {
  title?: string;
  message: string;
  apiMessage: string;
  setMessage: (message: string) => void;
}

const ErrorHandler: React.FC<ErrorHandlerProps> = ({
  title = "Message",
  message,
  apiMessage,
  setMessage,
}: ErrorHandlerProps): JSX.Element => {
  const [open, setOpen] = useState<boolean>(true);
  const [collapseOpen, setcollapseOpen] = useState<boolean>(false);

  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('xl'));

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleOK = (): void => {
    setMessage("");
    setOpen(false);
  };

  const handleCollapseClick = (): void => {
    setcollapseOpen(!collapseOpen);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      sx={{ zIndex: "1600" }}
      data-display-name="ErrorHandler"
    >
      <DialogTitle>{title}</DialogTitle>
      <Divider variant="fullWidth" />
      <DialogContent
        sx={{ padding: 0, width: "500px", overflowX: "auto" }}
        data-display-name="DialogContent"
      >
        <Box sx={{ padding: "0px 20px", margin: "20px 0" }}>
          <Typography align="left" variant={mobileDevice ? "h4" : "h2"}>
            There seems to be a problem.
          </Typography>
          <br></br>
          <Typography align="left" variant="body1">
            Some kind of error occurred in the API. Please contact our support
            team if the problem persists.
          </Typography>
          {(message !== "" || apiMessage !== "") && (
            <>
              <br></br>
              <Button
                sx={{
                  padding: 0,
                  width: "100%",
                  display: "inline",
                }}
                onClick={handleCollapseClick}
              >
                <SidebarPanelLabel
                  text="Error"
                  sampleUp={collapseOpen}
                  color={"#808080"}
                  backgroundColor={"#fff"}
                />
              </Button>
              {collapseOpen && (
                <>
                  {message !== "" && (
                    <>
                      <br></br>
                      <Typography align="left" variant="body2">
                        {message}
                      </Typography>
                    </>
                  )}
                  {apiMessage !== "" && (
                    <>
                      <br></br>
                      <Typography align="left" variant="body2">
                        {apiMessage}
                      </Typography>
                    </>
                  )}
                </>
              )}
            </>
          )}
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", margin: "0 0 20px 0" }}>
        <Button
          sx={{ width: "219px" }}
          onClick={handleOK}
          color="secondary"
          variant="contained"
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ErrorHandler;
