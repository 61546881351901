import { Action, ACTION_TYPES } from "./actions";
import { AuthState } from "./types";

const reducer = (state: AuthState, action: Action): AuthState => {
  let newState = state;
  switch (action.type) {
    case ACTION_TYPES.INITIALIZE: {
      const { isAuthenticated, user, navigationContent } = action.payload;
      newState = {
        ...state,
        isAuthenticated,
        isInitialized: true,
        user,
        navigationContent,
      };
      break;
    }
    case ACTION_TYPES.LOGIN: {
      const { user, navigationContent } = action.payload;
      newState = {
        ...state,
        isAuthenticated: true,
        user,
        navigationContent,
      };
      break;
    }
    case ACTION_TYPES.LOGOUT: {
      newState = {
        ...state,
        isAuthenticated: false,
        user: null,
        navigationContent: null,
      };
      break;
    }
    default: {
      newState = { ...state };
    }
  }

  return newState;
};

export default reducer;
