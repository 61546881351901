import React, { FC, useState } from "react";
import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Delete } from "@mui/icons-material";
import { axiosInstance } from "src/utils";
import { snackActions } from "src/utils/SnackbarUtils";
import { useProfile } from "src/contexts/ProfileContext";

interface Props {
  [key: string]: any;
}

const ImageUpload: FC<Props> = ({ assetPath, onImageChange }) => {
  const { state: profileState, getAvatar } = useProfile();
  const [isOpen, setIsOpen] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const deleteAvatar = () => {
    axiosInstance
      .delete(`/User/DeleteAvatar`)
      .then((res) => {
        snackActions.success("Avatar deleted");
      })
      .catch((err) => {
        snackActions.error("Failed to delete avatar");
      });
  };

  const handleDelete = async () => {
    await deleteAvatar();
    setIsOpen(false);

    if (getAvatar && profileState && profileState.user)
      getAvatar(profileState.user.EmployeeID);
  };

  return (
    <div
      style={{
        position: "relative",
      }}
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Avatar
        alt="Avatar"
        src={assetPath}
        sx={{
          width: 64,
          height: 64,
        }}
      />
      <input
        id="icon-button-file"
        accept="image/*"
        multiple
        type="file"
        onChange={onImageChange}
        style={{ display: "none" }}
      />
      <label htmlFor="icon-button-file">
        {isHovered && (
          <>
            <IconButton
              sx={{
                position: "absolute",
                top: "50%",
                left: "25%",
                transform: "translate(-50%, -50%)",
                backgroundColor: "#fff",
                padding: "2px",
                "&:hover": { backgroundColor: "rgba(233, 233, 233, 0.7)" },
              }}
              component="span"
              size="large"
            >
              <EditIcon />
            </IconButton>
          </>
        )}
      </label>
      {isHovered && (
        <IconButton
          sx={{
            position: "absolute",
            top: "50%",
            left: "75%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "#fff",
            padding: "2px",
            "&:hover": { backgroundColor: "rgba(233, 233, 233, 0.7)" },
          }}
          component="span"
          onClick={() => setIsOpen(true)}
          size="large"
        >
          <Delete />
        </IconButton>
      )}
      <Dialog open={isOpen}>
        <DialogContent>
          <Typography variant="h4" sx={{ mb: 2 }}>
            Are you sure you want to delete your avatar?
          </Typography>
          <Box display="flex" justifyContent="flex-end">
            <Button sx={{ mr: 2 }} onClick={() => setIsOpen(false)}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleDelete}>
              Confirm
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ImageUpload;
