import {
  Alert,
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { Formik } from "formik";
import type { FC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import { BV_APP_NAME } from "src/constants";
import services from "src/contexts/services";
import { Region } from "src/enums";
import { axiosInstance, validationMessage } from "src/utils";
import * as Yup from "yup";

interface ForgotProps {
  className?: string;
}

const Forgot: FC<ForgotProps> = ({ className, ...rest }: ForgotProps) => {
  const [successMessage, setSuccessMessage] = useState("");
  const [step, setStep] = useState(1);
  const { t } = useTranslation();
  const regions = [Region.EASTERN, Region.WESTERN, Region.QUEBEC];

  switch (step) {
    case 1:
      return (
        <div>
          <Typography variant="h2">{t("common:resetYourPassword")}</Typography>
          <Typography>{t("common:enterEmailBelow")}</Typography>
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              email: "",
              submit: null as string,
              region: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email(t("common:emailValidation"))
                .max(255)
                .required(validationMessage(t("common:emailAddress"))),
              region: Yup.string()
                .max(255)
                .required(validationMessage(t("common:region"))),
            })}
            onSubmit={async (
              values,
              { setErrors, setStatus, setSubmitting }
            ) => {
              try {
                const response = await axiosInstance.get(
                  `${services.generateBaseUrl(
                    values.region
                  )}/User/ResetPassword`,
                  {
                    params: {
                      email: values.email,
                    },
                  }
                );

                setSuccessMessage(response.data.Message);
                setStatus({ success: true });
                setSubmitting(false);
                setStep((prev) => prev + 1);
              } catch (err) {
                console.error(err);
                console.log(`${err.data}`);
                console.log(err.data);
                setStatus({ success: false });
                setErrors({
                  submit: `${err.data.Message}`,
                });
                setSubmitting(false);
              }
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              touched,
              values,
            }) => (
              <form
                noValidate
                onSubmit={handleSubmit}
                className={className}
                {...rest}
              >
                <Box mt={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        error={Boolean(touched.email && errors.email)}
                        fullWidth
                        autoFocus
                        helperText={touched.email && errors.email}
                        label={t("common:emailAddress")}
                        name="email"
                        id="email"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        type="email"
                        value={values.email}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl fullWidth>
                        <Autocomplete
                          id="region-autocomplete"
                          options={regions}
                          value={values.region}
                          onChange={(e, value: any) => {
                            setFieldValue("region", value);
                          }}
                          autoHighlight
                          fullWidth
                          renderInput={(params) => {
                            return (
                              <TextField
                                {...params}
                                label={t("common:region")}
                                error={touched.region && Boolean(errors.region)}
                                helperText={touched.region && errors.region}
                              />
                            );
                          }}
                        />
                      </FormControl>
                    </Grid>

                    {errors?.submit && (
                      <Grid item xs={12}>
                        <Typography color="#f44336">
                          {errors?.submit}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item md={6} xs={12}>
                      <Button
                        fullWidth
                        color="primary"
                        disabled={isSubmitting}
                        type="submit"
                        variant="contained"
                      >
                        {t("common:submit")}
                      </Button>
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <Button
                        fullWidth
                        color="primary"
                        to="/login"
                        component={RouterLink}
                      >
                        {t("common:cancel")}
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </form>
            )}
          </Formik>
        </div>
      );
    case 2:
      return (
        <div>
          <Typography variant="h2">{t("common:logInToBv")}</Typography>
          <Typography>{successMessage}</Typography>
          <Box mt={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  color="primary"
                  variant="contained"
                  to="/login"
                  component={RouterLink}
                >
                  {t("common:returnToLogin")}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </div>
      );
    case 3:
      return (
        <Box mt={3}>
          <Typography variant="h2">{BV_APP_NAME}</Typography>
          <Alert severity="info">{successMessage}</Alert>
          <Box mt={3}>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              to="/login"
              component={RouterLink}
            >
              Back to Login
            </Button>
          </Box>
        </Box>
      );
  }
};

export default Forgot;
