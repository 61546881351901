import type { IContent } from "src/types/IContent";
import type { IUser } from "src/types/IUser";

export enum ACTION_TYPES {
  INITIALIZE,
  LOGIN,
  LOGOUT,
}

type InitialiseAction = {
  type: ACTION_TYPES.INITIALIZE;
  payload: {
    isAuthenticated: boolean;
    user: IUser | null;
    navigationContent: IContent | null;
  };
};

type LoginAction = {
  type: ACTION_TYPES.LOGIN;
  payload: {
    user: IUser;
    navigationContent: IContent | null;
  };
};

type LogoutAction = {
  type: ACTION_TYPES.LOGOUT;
};

export type Action = InitialiseAction | LoginAction | LogoutAction;
