export const getInitials = (name = "") => {
  return name
    .replace(/\s+/, " ")
    .split(" ")
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join("");
};

export const pluralize = (count, noun, suffix = "s") =>
  `${noun}${count !== 1 ? suffix : ""}`;
