import { AxiosResponse } from "axios";
import {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from "react";
import { IBin } from "src/types/IBin";
import { IBottle } from "src/types/IBottle";
import { ICarrier } from "src/types/ICarrier";
import { IClient } from "src/types/IClient";
import { ILongestTatValue } from "src/types/ILongestTatValue";
import { IMatrix } from "src/types/IMatrix";
import { IPrefix } from "src/types/IPrefix";
import { IPrefixGroup } from "src/types/IPrefixGroup";
import { ISampleType } from "src/types/ISampleType";
import { ITatOption } from "src/types/ITatOption";
import { axiosInstance } from "src/utils";

interface LookupsState {
  clients: IClient[];
  prefixes: IPrefix[];
  carriers: ICarrier[];
  bins: IBin[];
  longestTatValues: ILongestTatValue[];
  tatOptions: ITatOption[];
  sampleTypes: ISampleType[];
  bottles: IBottle[];
  matrices: IMatrix[];
  prefixGroups: IPrefixGroup[];
  isLoading: any;
}

interface LookupsContextValue {
  state?: LookupsState | null;
  dispatch?: (value) => void;
  getClients?: () => Promise<void | AxiosResponse<any, any>>;
  getPrefixes?: () => Promise<void | AxiosResponse<any, any>>;
  getCarriers?: () => Promise<void | AxiosResponse<any, any>>;
  getBins?: () => Promise<void | AxiosResponse<any, any>>;
  getLongestTatValues?: () => Promise<void | AxiosResponse<any, any>>;
  getTatOptions?: (longestTatValue) => Promise<void | AxiosResponse<any, any>>;
  getSampleTypes?: () => Promise<void | AxiosResponse<any, any>>;
  getBottles?: () => Promise<void | AxiosResponse<any, any>>;
  getMatrices?: () => Promise<void | AxiosResponse<any, any>>;
  getPrefixGroups?: () => Promise<void | AxiosResponse<any, any>>;
}

const LookupsContext = createContext<LookupsContextValue>({});
const useLookups = (): LookupsContextValue => useContext(LookupsContext);

const reducer = (state: LookupsState, action): LookupsState => {
  let newState = state;

  switch (action.type) {
    case "IS_LOADING": {
      const { parameter, status } = action.payload;
      newState = {
        ...state,
        isLoading: { ...state.isLoading, [parameter]: status },
      };
      break;
    }
    case "GET_CLIENTS": {
      const { clients }: { clients: IClient[] } = action.payload;
      newState = {
        ...state,
        clients,
      };
      break;
    }
    case "GET_PREFIXES": {
      const { prefixes }: { prefixes: IPrefix[] } = action.payload;
      newState = {
        ...state,
        prefixes,
      };
      break;
    }
    case "GET_CARRIERS": {
      const { carriers }: { carriers: ICarrier[] } = action.payload;
      newState = {
        ...state,
        carriers,
      };
      break;
    }
    case "GET_BINS": {
      const { bins }: { bins: IBin[] } = action.payload;
      newState = {
        ...state,
        bins,
      };
      break;
    }
    case "GET_LONGEST_TAT_VALUES": {
      const { longestTatValues }: { longestTatValues: ILongestTatValue[] } =
        action.payload;
      newState = {
        ...state,
        longestTatValues,
      };
      break;
    }
    case "GET_TAT_OPTIONS": {
      const { tatOptions }: { tatOptions: ITatOption[] } = action.payload;
      newState = {
        ...state,
        tatOptions,
      };
      break;
    }
    case "GET_SAMPLE_TYPES": {
      const { sampleTypes }: { sampleTypes: ISampleType[] } = action.payload;
      newState = {
        ...state,
        sampleTypes,
      };
      break;
    }
    case "GET_BOTTLES": {
      const { bottles }: { bottles: any } = action.payload;
      newState = {
        ...state,
        bottles,
      };
      break;
    }
    case "GET_MATRICES": {
      const { matrices }: { matrices: any } = action.payload;
      newState = {
        ...state,
        matrices,
      };
      break;
    }
    case "GET_PREFIX_GROUPS": {
      const { prefixGroups }: { prefixGroups: any } = action.payload;
      newState = {
        ...state,
        prefixGroups,
      };
      break;
    }

    default: {
      return state;
    }
  }
  return newState;
};

interface LookupsProps {
  children: ReactNode;
}

const LookupsProvider: FC<LookupsProps> = ({ children }: LookupsProps) => {
  const initialState: LookupsState = {
    clients: [],
    prefixes: [],
    carriers: [],
    bins: [],
    longestTatValues: [],
    tatOptions: [],
    sampleTypes: [],
    bottles: [],
    matrices: [],
    prefixGroups: [],
    isLoading: {
      clients: false,
      prefixes: false,
      carriers: false,
      bins: false,
      tatOptions: false,
      sampleTypes: false,
      prefixGroups: false,
    },
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  const getClients = useCallback(() => {
    dispatch({
      type: "IS_LOADING",
      payload: { parameter: "clients", status: true },
    });

    return axiosInstance
      .get(`/AutomationHero/Clients`)
      .then((response) => {
        let clients: IClient[] = response.data.ResponseData;

        dispatch({
          type: "GET_CLIENTS",
          payload: {
            clients,
          },
        });

        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "clients", status: false },
        });

        return response;
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "clients", status: false },
        });
      });
  }, []);

  const getPrefixes = useCallback(() => {
    dispatch({
      type: "IS_LOADING",
      payload: { parameter: "prefixes", status: true },
    });

    return axiosInstance
      .get(`/AutomationHero/Prefixes`)
      .then((response) => {
        let prefixes: IPrefix[] = response.data.ResponseData;

        dispatch({
          type: "GET_PREFIXES",
          payload: {
            prefixes,
          },
        });

        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "prefixes", status: false },
        });

        return response;
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "prefixes", status: false },
        });
      });
  }, []);

  const getCarriers = useCallback(() => {
    dispatch({
      type: "IS_LOADING",
      payload: { parameter: "carriers", status: true },
    });

    return axiosInstance
      .get(`/AutomationHero/Carriers`)
      .then((response) => {
        let carriers: ICarrier[] = response.data.ResponseData;

        dispatch({
          type: "GET_CARRIERS",
          payload: {
            carriers,
          },
        });

        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "carriers", status: false },
        });

        return response;
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "carriers", status: false },
        });
      });
  }, []);

  const getBins = useCallback(() => {
    dispatch({
      type: "IS_LOADING",
      payload: { parameter: "bins", status: true },
    });

    return axiosInstance
      .get(`/AutomationHero/Bins`)
      .then((response) => {
        let bins: IBin[] = response.data.ResponseData;

        dispatch({
          type: "GET_BINS",
          payload: {
            bins,
          },
        });

        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "bins", status: false },
        });

        return response;
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "bins", status: false },
        });
      });
  }, []);

  const getLongestTatValues = useCallback(() => {
    dispatch({
      type: "IS_LOADING",
      payload: { parameter: "longestTatValues", status: true },
    });

    return axiosInstance
      .get(`/AutomationHero/LongestTatValues`)
      .then((response) => {
        let longestTatValues: ITatOption[] = response.data.ResponseData;

        dispatch({
          type: "GET_LONGEST_TAT_VALUES",
          payload: {
            longestTatValues,
          },
        });

        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "longestTatValues", status: false },
        });

        return response;
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "longestTatValues", status: false },
        });
      });
  }, []);

  const getTatOptions = useCallback((longestTatValue) => {
    dispatch({
      type: "IS_LOADING",
      payload: { parameter: "tatOptions", status: true },
    });

    return axiosInstance
      .get(`/AutomationHero/TatOptions/${longestTatValue}`)
      .then((response) => {
        let tatOptions: ITatOption[] = response.data.ResponseData;

        dispatch({
          type: "GET_TAT_OPTIONS",
          payload: {
            tatOptions,
          },
        });

        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "tatOptions", status: false },
        });

        return response;
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "tatOptions", status: false },
        });
      });
  }, []);

  const getSampleTypes = useCallback(() => {
    dispatch({
      type: "IS_LOADING",
      payload: { parameter: "sampleTypes", status: true },
    });

    return axiosInstance
      .get(`/AutomationHero/SampleTypes`)
      .then((response) => {
        let sampleTypes: ISampleType[] = response.data.ResponseData;

        dispatch({
          type: "GET_SAMPLE_TYPES",
          payload: {
            sampleTypes,
          },
        });

        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "sampleTypes", status: false },
        });

        return response;
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "sampleTypes", status: false },
        });
      });
  }, []);

  const getBottles = useCallback(() => {
    dispatch({
      type: "IS_LOADING",
      payload: { parameter: "bottles", status: true },
    });

    return axiosInstance
      .get(`/AutomationHero/Bottles`)
      .then((response) => {
        let bottles: any[] = response.data.ResponseData;

        dispatch({
          type: "GET_BOTTLES",
          payload: {
            bottles,
          },
        });

        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "bottles", status: false },
        });

        return response;
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "bottles", status: false },
        });
      });
  }, []);

  const getMatrices = useCallback(() => {
    dispatch({
      type: "IS_LOADING",
      payload: { parameter: "matrices", status: true },
    });

    return axiosInstance
      .get(`/AutomationHero/Matrices`)
      .then((response) => {
        let matrices: any[] = response.data.ResponseData;

        dispatch({
          type: "GET_MATRICES",
          payload: {
            matrices,
          },
        });

        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "matrices", status: false },
        });

        return response;
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "matrices", status: false },
        });
      });
  }, []);

  const getPrefixGroups = useCallback(() => {
    dispatch({
      type: "IS_LOADING",
      payload: { parameter: "prefixGroups", status: true },
    });

    return axiosInstance
      .get(`/AutomationHero/PrefixGroups`)
      .then((response) => {
        let prefixGroups: any[] = response.data.ResponseData;

        dispatch({
          type: "GET_PREFIX_GROUPS",
          payload: {
            prefixGroups,
          },
        });

        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "prefixGroups", status: false },
        });

        return response;
      })
      .catch((err) => {
        console.error(err);
        dispatch({
          type: "IS_LOADING",
          payload: { parameter: "prefixGroups", status: false },
        });
      });
  }, []);

  const value = useMemo(
    () => ({
      state,
      dispatch,
      getClients,
      getPrefixes,
      getCarriers,
      getBins,
      getLongestTatValues,
      getTatOptions,
      getSampleTypes,
      getBottles,
      getMatrices,
      getPrefixGroups,
    }),
    [
      state,
      getClients,
      getPrefixes,
      getCarriers,
      getBins,
      getLongestTatValues,
      getTatOptions,
      getSampleTypes,
      getBottles,
      getMatrices,
      getPrefixGroups,
    ]
  );

  return (
    <LookupsContext.Provider value={value}>{children}</LookupsContext.Provider>
  );
};

export { LookupsProvider, useLookups };
