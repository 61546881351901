import jwtDecode from "jwt-decode";
import i18n from "src/i18n";
import type { IAppMetadata } from "src/types/IAppMetadata";
import type { IContent } from "src/types/IContent";
import type { ISection } from "src/types/ISection";
import type { IUser } from "src/types/IUser";
import { axiosInstance as axios } from "src/utils";
import { findNavigation, findRoute } from "../RouteFactory";
import { ResponseData } from "./types";

const services = {
  isValidToken: (accessToken: string): boolean => {
    if (!accessToken) {
      return false;
    }

    const decoded: any = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  },

  setSession: (
    identityToken: string | null,
    refreshToken: string | null,
    region?: string
  ): void => {
    if (identityToken) {
      window.localStorage.setItem("IdentityToken", identityToken);
      window.localStorage.setItem("RefreshToken", refreshToken);
      window.localStorage.setItem("Region", region);
      axios.defaults.headers.common.IdentityToken = identityToken;
      axios.defaults.headers.common.RefreshToken = refreshToken;
      axios.defaults.baseURL = services.generateBaseUrl(region);
    } else {
      window.localStorage.removeItem("IdentityToken");
      window.localStorage.removeItem("RefreshToken");
      window.localStorage.removeItem("Region");
      delete axios.defaults.headers.common.IdentityToken;
      delete axios.defaults.headers.common.RefreshToken;
      delete axios.defaults.headers.common.Region;
    }
  },

  getNavigationMenu: (uiMetadata: IAppMetadata): ISection[] | [] => {
    const sections = findNavigation();

    try {
      const viewMetadata = uiMetadata.FormsMetaData?.find(
        (x) => x.ViewCode === window.VIEW_CODE
      );

      const menus = viewMetadata === undefined ? null : viewMetadata.Menus;
      if (menus !== undefined) {
        sections.forEach((section) => {
          section.items.forEach((item, index2, arr2) => {
            if (item.sectionKey !== undefined && !arr2[index2].hasAccess) {
              const menu = menus.find(
                (x) =>
                  x.MenuName.toLowerCase() === item.sectionKey.toLowerCase()
              );
              arr2[index2].hasAccess = menu !== undefined && menu.HasAccess;
            }
          });
        });
      }
    } catch (error) {
      console.error(error);
    }
    return sections.map((item) => {
      return {
        ...item,
        items: item.items.filter((i) => i.hasAccess),
      };
    });
  },

  getNavigationContent: async (): Promise<IContent> => {
    let navigationContent: IContent = {};

    const formsMetadata = {
      Application: "SampleReception",
      FormsMetaData: [],
    };

    let appMetadata: IAppMetadata = formsMetadata;

    navigationContent = {
      redirectTo: "/",
      noAccessRedirectTo: "/noAccess",
      sections:
        appMetadata === undefined
          ? findNavigation()
          : services.getNavigationMenu(appMetadata),
      routes: findRoute(),
      appMetadata,
    };

    return navigationContent;
  },

  changeLanguage: (userLanguage: string): void => {
    i18n.changeLanguage(userLanguage, (err, t) => {
      if (err) return console.log("something went wrong loading", err);
      return t("key");
    });
  },

  getUser: async (): Promise<IUser> => {
    const response = await axios.get<ResponseData>("user?moreInfo=true");
    const user: IUser = response.data.ResponseData;
    return user;
  },

  generateBaseUrl: (region: string): string => {
    //@ts-ignore
    const _region = window.apiRegions.find((x) => x.label === region);
    return _region?.apiURL;
  },

  login: async (
    email: string,
    password: string,
    region: string
  ): Promise<IUser> => {
    const basic = btoa(`${email}:${password}`);
    services.setSession(null, null);
    axios.defaults.baseURL = services.generateBaseUrl(region);
    const response = await axios.get<ResponseData>("user/AHAuthentication", {
      headers: {
        Authorization: `Basic ${basic}`,
        Region: window.localStorage.getItem("Region"),
        TimezoneOffset: new Date().getTimezoneOffset().toString(),
      },
    });

    const user: IUser = response.data.ResponseData;

    const { identitytoken, refreshtoken } = response.headers;

    if (identitytoken && services.isValidToken(identitytoken)) {
      services.setSession(identitytoken, refreshtoken, region);
      services.changeLanguage(user?.UserLanguage ?? "E");
    }

    return user;
  },

  logout: async (): Promise<void> => {
    try {
      await axios.post<void>("user/SignOut");
    } finally {
      services.setSession(null, null);
    }
  },

  onError: (error: any): void => {
    services.logout();
    console.log(error);
    if (error?.data === undefined) {
      if (error?.status === 401) {
        throw new Error(
          "Unauthorized - Please contact Support to check your access.",
          { cause: { status: error.status } }
        );
      } else {
        throw error;
      }
    } else {
      throw new Error(error.data.Message, { cause: { status: error.status } });
    }
  },
};

export default services;
