import { useContext } from "react";
import appConfiguration from "src/appConfiguration";

const useAuth = () => {
  const context = useContext(appConfiguration.use().authContext);
  if (!context) {
    throw new Error(
      "useAuth must be used within an AuthProvider. Wrap a parent component in <AuthProvider> to fix the error."
    );
  }
  return context;
};

export default useAuth;
