import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanageDeector from "i18next-browser-languagedetector";
import resources from "./translations";

// Instructions on how to use translations can be found here:
// https://react.i18next.com/guides/quick-start

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanageDeector)
  .init({
    debug: false,
    fallbackLng: "en",
    resources,
    keySeparator: ".",
    interpolation: {
      escapeValue: false, // react is already safe from xss
    },
    parseMissingKeyHandler: (key) => {
      if (!(process.env.NODE_ENV === "production" || key === "key")) {
        //console.log(`Failed to lookup translation key: '${key}'`);
      }
      return key;
    },
    missingKeyHandler: (lng, ns, key) => {
      if (process.env.NODE_ENV !== "production") {
        //console.log(`Failed to lookup key '${key}' in namespace '${ns}' for language '${lng}'`);
      }
    },
  });

export default i18n;
