import { css } from "@emotion/css";
import { useTheme } from "@emotion/react";
import { useMemo } from "react";

const useClasses = (styles, props = {}) => {
  const theme = useTheme();
  return useMemo(() => {
    const classes = {};
    const styleElements = Array.isArray(styles) ? styles : [styles];
    styleElements.forEach((item) => {
      const baseCtr =
        typeof item === "function" ? item({ theme, ...props }) : item;

      Object.entries(baseCtr).forEach(([key]) => {
        classes[key] = css(baseCtr[key]);
      });
    });

    return classes;
  }, [styles, theme, props]);
};

export default useClasses;
