declare const window: any;

const process = {
  env: {
    REACT_APP_GA_MEASUREMENT_ID: "",
    NODE_ENV: "development",
  },
};

const track = (...args: any[]): void => {
  if (process.env.NODE_ENV !== "production") {
    return;
  }

  if (!window.gtag) {
    return;
  }

  window.gtag(...args);
};

export const pageview = (props: any): void => {
  track("config", process.env.REACT_APP_GA_MEASUREMENT_ID, props);
};

export const event = (type: string, props: Record<string, any>) => {
  track("event", type, props);
};
