import {
  colors,
  responsiveFontSizes,
  DeprecatedThemeOptions,
} from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { deepmerge } from "@mui/utils";
import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { LinkProps } from "@mui/material/Link";
import { strongShadows } from "./shadows";
import typography from "./typography";

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (MUI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

declare module "@mui/material/styles" {
  interface Theme {
    TableDetailCell: {
      styleOverrides: {
        active: {
          backgroundColor: "rgba(0,0,0,0.075)";
        };
      };
    };
    background: {
      dark: string;
    };
  }
  interface Colors {
    neutralGrey: string;
    darkBackground: string;
  }
  interface Palette {
    colors: Colors;
  }
}

const primary = "#FC3650";
const secondary = "#333";

const baseOptions = {
  direction: "ltr",
  typography,

  components: {
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior,
      },
    },
    MuiButtonContained: {
      styleOverrides: {
        root: {
          backgroundColor: primary,
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: "hidden",
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 32,
        },
      },
    },
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      } as LinkProps,
      styleOverrides: {
        root: {
          cursor: "pointer",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          color: secondary,
          backgroundColor: "blue",
        },
        filled: {
          color: "#fff",
          backgroundColor: primary,
          "&:hover": {
            backgroundColor: "rgb(176, 37, 56)",
          },
        },
        outlined: {
          color: secondary,
          backgroundColor: "rgb(241, 241, 241)",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: secondary,
        },
        asterisk: {
          color: primary,
          "&$error": {
            color: primary,
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          fill: primary,
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        popupIndicator: {
          color: primary,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: { fontWeight: "bold" },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          "&$completed": {
            color: "#FC3650!important",
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        item: {
          padding: "0px",
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            "&.Mui-error fieldset": {
              borderWidth: 3,
            },
          },
        },
      },
    },
  },
};

const themeOptions = {
  palette: {
    mode: "light",
    background: {
      default: colors.common.white,
      paper: colors.common.white,
    },
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    colors: {
      neutralGrey: "#",
      darkBackground: "#f4f6f8",
    },
    shape: {
      borderRadius: 0,
    },
  },
  shadows: strongShadows,
};

export const theme = responsiveFontSizes(
  createTheme(deepmerge(baseOptions, themeOptions) as DeprecatedThemeOptions)
);
