import { AddAlarm, Dashboard, People } from "@mui/icons-material";
import type { ISection } from "src/types/ISection";

const sections: ISection[] = [
  {
    subheader: "Main",
    items: [
      {
        title: "Samples",
        href: "/samples",
        icon: Dashboard,
        sectionKey: "dashboard",
        hasAccess: true,
      },
      {
        title: "Cooler Entry",
        href: "/cooler",
        icon: AddAlarm,
        sectionKey: "flag",
        hasAccess: true,
      },
    ],

  },
  {
    subheader: "Management",
    items: [
      {
        title: "Users",
        href: "#",
        icon: People,
        sectionKey: "users",
        hasAccess: true,
        items: [
          {
            title: "Search Roster",
            href: "/users/search",
            sectionKey: "usersSearch",
            hasAccess: true,
          },
          {
            title: "View Roster",
            href: "/users/view",
            sectionKey: "usersView",
            hasAccess: true,
          },
          {
            title: "Add New",
            href: "/users/add",
            sectionKey: "usersAdd",
            hasAccess: true,
          },
          {
            title: "Requires Attention",
            href: "/users/attention",
            sectionKey: "usersAttention",
            hasAccess: true,
          },
          {
            title: "Submitted & Approved",
            href: "/users/status",
            sectionKey: "usersStatus",
            hasAccess: true,
          },
        ],
      },
    ],
  },
];

export default sections;
