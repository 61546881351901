import type { FC } from "react";
import React from "react";
import logo from "src/assets/bv-logo-black.svg";

interface BVLogoProps {
  [key: string]: any;
}

const BVLogo: FC<BVLogoProps> = (props) => (
  <img
    alt="Logo"
    src={logo}
    data-display-name="BVLogo"
    width={100}
    {...props}
  />
);

export default BVLogo;
