import type { ReactNode } from "react";
import * as React from "react";

interface State {
  dirty?: boolean;
  open?: boolean;
  path?: string;
  preventTransition?: boolean;
}

const initialState: State = {
  dirty: false,
  open: false,
  path: "",
  preventTransition: false,
};

interface PromptValue {
  state: State;
  initialState?: State;
  handleState?: (state: State) => void;
}

const PromptContext = React.createContext<PromptValue>({ state: initialState });
const usePrompt = (): PromptValue => {
  const context = React.useContext(PromptContext);
  if (!context) {
    throw new Error(
      "usePrompt must be used within an PromptProvider. Wrap a parent component in <PromptProvider> to fix the error."
    );
  }
  return context;
};

interface ProviderProps {
  children: ReactNode;
}

const PromptProvider: React.FC<ProviderProps> = ({
  children,
}: ProviderProps): JSX.Element => {
  const [state, setState] = React.useState<State>(initialState);

  const handleState = React.useCallback(
    (promptState: State): void => {
      if (state.preventTransition || promptState.preventTransition) {
        setState((prev) => ({ ...prev, ...promptState }));
      }
    },
    [state]
  );

  return (
    <PromptContext.Provider value={{ state, initialState, handleState }}>
      {children}
    </PromptContext.Provider>
  );
};

export { PromptProvider, usePrompt };
