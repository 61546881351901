import axios from "axios";
import config from "src/config";

export const axiosInstance = axios.create({
  baseURL: config.baseURL,
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      error.response
        ? error.response
        : error.request
        ? error.request
        : `Something went wrong - ${error}`
    )
);
