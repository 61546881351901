import React from "react";
import type { FC } from "react";

import CircularProgress from "@mui/material/CircularProgress";
import { styled } from "@mui/material/styles";

const Root = styled("div")(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.palette.background.default,
  display: "flex",
  flexDirection: "column",
  height: "100%",
  justifyContent: "center",
  minHeight: "100%",
  padding: theme.spacing(3),
}));

const LoadingScreen: FC = () => {
  return (
    <Root data-display-name="LoadingScreen">
      <CircularProgress color="secondary" />
    </Root>
  );
};

export default LoadingScreen;
