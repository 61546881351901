import {
  Box,
  Button,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListSubheader,
  Tooltip,
} from "@mui/material";
import clsx from "clsx";
import React, { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import {
  matchPath,
  NavLink as RouterLink,
  useLocation,
} from "react-router-dom";
import BVLogo from "src/components/BVLogo";
import useAuth from "src/hooks/useAuth";
import useClasses from "src/hooks/useClasses";
import { usePrompt } from "src/hooks/usePrompt";
import type { IItem } from "src/types/ISection";
import NavItem from "./NavItem";

interface NavBarProps {
  isMenuOpen: boolean;
  onMenuClose: () => void;
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
}: {
  acc: any[];
  pathname: string;
  item: IItem;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = matchPath(pathname, item.href);

    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
        access={item.hasAccess}
      >
        {renderNavItems({
          items: item.items,
          pathname,
          depth: depth + 1,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
        access={item.hasAccess}
      />
    );
  }

  return acc;
}

function renderNavItems({
  items,
  pathname,
  depth = 0,
}: {
  items: IItem[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) =>
          reduceChildRoutes({
            acc,
            item,
            pathname,
            depth,
          }),
        []
      )}
    </List>
  );
}

const styles = ({ theme }) => ({
  nbDrawer: {
    width: 256,
    top: 64,
    height: "calc(100% - 64px)",
  },
  drawerOpen: {
    width: 256,
  },
  drawerClose: {
    overflowX: "hidden",
    width: 0,
    display: "none",
  },
});

const NavBar: React.FC<NavBarProps> = ({
  isMenuOpen,
  onMenuClose,
}: NavBarProps) => {
  const { navigationContent } = useAuth();
  const location = useLocation();
  const classes: any = useClasses(styles);
  const { state, handleState } = usePrompt();

  const { sections } = navigationContent;

  useEffect(() => {
    if (isMenuOpen && onMenuClose) {
      onMenuClose();
    }
  }, [location.pathname, isMenuOpen, onMenuClose]);

  const handleToggle = (
    access: boolean,
    path: string,
    e: React.SyntheticEvent
  ): void => {
    if (!access || state.dirty) {
      e.preventDefault();
      if (path !== state.path) {
        handleState({ ...state, open: state.dirty, path });
      }
    }
  };

  const menuBar = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2} display="flex" justifyContent="center">
          <RouterLink
            onClick={(e: React.SyntheticEvent) =>
              handleToggle(true, navigationContent.redirectTo, e)
            }
            to={navigationContent.redirectTo}
          >
            <BVLogo width="100%" />
          </RouterLink>
        </Box>
        <Divider />
        <Box p={2}>
          {sections.map((section) => (
            <List
              key={section.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {section.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: section.items,
                pathname: location.pathname,
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  const actionBar = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Box p={2} display="flex" justifyContent="center">
          <Tooltip title="My Dashboard" placement="right">
            <Button
              fullWidth
              size="large"
              type="submit"
              component={RouterLink}
              onClick={(e: React.SyntheticEvent) =>
                handleToggle(true, navigationContent.redirectTo, e)
              }
              to={navigationContent.redirectTo}
            >
              <BVLogo width="100%" />
            </Button>
          </Tooltip>
        </Box>
        <Divider />
        <Box p={0} textAlign="center">
          {sections.map((section) =>
            section.items.map(
              ({
                icon: Icon,
                href,
                title,
                hasAccess,
              }: {
                icon?: any;
                title?: string;
                href?: string;
                hasAccess?: boolean;
              }) => {
                const access = hasAccess && hasAccess === true;
                const toPath = access ? href : "/noaccess";

                return (
                  <Tooltip
                    key={`NavbarTooltip${title}`}
                    title={title}
                    placement="right"
                  >
                    <IconButton
                      disabled={!access}
                      color="secondary"
                      sx={{
                        "&.Mui-disabled": {
                          pointerEvents: "auto",
                        },
                        "&.active": {
                          color: "primary.main",
                        },
                      }}
                      component={RouterLink}
                      to={toPath}
                      onClick={(e: React.SyntheticEvent) =>
                        handleToggle(access, toPath, e)
                      }
                      size="large"
                    >
                      {Icon && <Icon />}
                    </IconButton>
                  </Tooltip>
                );
              }
            )
          )}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  const defaultProps = (): any => ({
    anchor: "left",
    variant:
      isMenuOpen && window.screen.availWidth < 1170 ? "temporary" : "permanent",
    onClose: onMenuClose,
  });

  return (
    <div data-display-name="NavBar">
      <Hidden mdUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.nbDrawer }}
          onClose={onMenuClose}
          open={isMenuOpen}
          variant="temporary"
        >
          {menuBar}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          {...defaultProps()}
          open
          className={clsx({
            [classes.drawerOpen]: isMenuOpen,
            [classes.drawerClose]: !isMenuOpen,
          })}
          classes={{
            paper: clsx(classes.nbDrawer, {
              [classes.drawerOpen]: isMenuOpen,
              [classes.drawerClose]: !isMenuOpen,
            }),
          }}
        >
          {isMenuOpen && menuBar}
          {!isMenuOpen && actionBar}
        </Drawer>
      </Hidden>
    </div>
  );
};

export default NavBar;
