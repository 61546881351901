import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import React, { FC } from "react";

interface IProps {
  text?: string;
  sampleUp?: boolean;
  color?: string;
  backgroundColor?: string;
  addBorder?: boolean;
}

const SidebarPanelLabel: FC<IProps> = ({
  text,
  sampleUp,
  color,
  backgroundColor,
  addBorder = true,
}: IProps): JSX.Element => {
  return (
    <Box
      sx={{
        padding: "2px",
        border: addBorder
          ? backgroundColor
            ? `1px solid ${backgroundColor}`
            : "1px solid #333"
          : "none",
        boxSizing: "border-box",
        borderRadius: "4px",
        color: color ? color : undefined,
        backgroundColor: backgroundColor ? backgroundColor : undefined,
        display: "flex",
        height: "100%",
      }}
      data-display-name="SidebarPanelLabel"
    >
      <Grid
        container
        spacing={1}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "row",
          flexWrap: "nowrap",
        }}
      >
        <Grid item xs={9}>
          <Typography
            sx={{
              paddingTop: "2px",
              paddingLeft: "4px",
              display: "flex",
              alignItems: "center",
              wordBreak: "break-word",
            }}
          >
            {text}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          {sampleUp ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default SidebarPanelLabel;
