import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  Hidden,
  Paper,
} from "@mui/material";
import type { FC, ReactNode } from "react";
import React, { useState } from "react";
import logo from "src/assets/bv-logo-coloured.svg";
import Privacy from "src/components/Privacy";
import Terms from "src/components/Terms";
import useClasses from "src/hooks/useClasses";

interface CrestProps {
  children?: ReactNode;
}

const styles = ({ theme }) => ({
  root: {
    width: "100%",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    // [theme.breakpoints.down("sm")]: {
    //   textAlign: "center",
    // },
  },
  paper: {
    width: 560,
    minHeight: 0,
    maxHeight: "100%",
    overflowY: "auto",
    overflowX: "auto",
    background: "#fff",
    padding: theme.spacing(5),
  },
  logoContainer: {
    textAlign: "center",
    paddingBottom: 20,
  },
  logo: {
    width: "100%",
    maxWidth: 71,
  },
  gridContainer: {
    height: "100%",
  },
  divider: {
    marginRight: "22px",
  },
  footer: {
    fontSize: 11,
    lineHeight: "16px",
    color: "#808080",
    padding: theme.spacing(1, 0, 0, 0),
  },
});

const Crest: FC<CrestProps> = ({ children }: CrestProps): JSX.Element => {
  const classes: any = useClasses(styles);
  const [showTerms, setShowTerms] = useState<boolean>(false);
  const [showPrivacy, setShowPrivacy] = useState<boolean>(false);

  const termsOnClick = (): void => setShowTerms(false);
  const showOnClick = (): void => setShowPrivacy(false);

  return (
    <div className={classes.root} data-display-name="Crest">
      <Dialog open={showTerms} onBackdropClick={termsOnClick}>
        <Box m={2}>
          <Terms />
          <br />
          <Button variant="outlined" color="primary" onClick={termsOnClick}>
            Close
          </Button>
        </Box>
      </Dialog>
      <Dialog open={showPrivacy} onBackdropClick={showOnClick}>
        <Box m={2}>
          <Privacy />
          <br />
          <Button variant="outlined" color="primary" onClick={showOnClick}>
            Close
          </Button>
        </Box>
      </Dialog>
      <Paper className={classes.paper}>
        <Grid
          container
          alignItems="stretch"
          direction="row"
          className={classes.gridContainer}
        >
          <Grid item xs={12} md={2} className={classes.logoContainer}>
            <img src={logo} alt="bv logo" className={classes.logo} />
          </Grid>
          <Hidden xlDown>
            <Grid item md={1}>
              <Divider
                className={classes.divider}
                orientation="vertical"
                variant="middle"
              />
            </Grid>
          </Hidden>
          <Grid item md xs={12}>
            {children}
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default Crest;
