import { styled } from "@mui/material/styles";
import React, { ReactNode, useState } from "react";
import NavBar from "./NavBar";
import TopBar from "./TopBar";

interface DashboardLayoutProps {
  children?: ReactNode;
}

const Root = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.colors.darkBackground,
  display: "flex",
  height: "100%",
  overflow: "hidden",
  width: "100%",
}));

const Wrapper = styled("div")(({ theme }) => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
  paddingTop: theme.spacing(8),
}));

const ContentContainer = styled("div")(() => ({
  display: "flex",
  flex: "1 1 auto",
  overflow: "hidden",
}));

const Content = styled("div")(() => ({
  flex: "1 1 auto",
  height: "100%",
  overflow: "auto",
}));

const DashboardLayout: React.FC<DashboardLayoutProps> = ({
  children,
}: DashboardLayoutProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  return (
    <Root data-display-name="DashboardLayout">
      <TopBar setOpen={(): void => setIsOpen(!isOpen)} />
      <NavBar isMenuOpen={isOpen} onMenuClose={(): void => setIsOpen(false)} />
      <Wrapper>
        <ContentContainer>
          <Content>{children}</Content>
        </ContentContainer>
      </Wrapper>
    </Root>
  );
};

export default DashboardLayout;
