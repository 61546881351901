const E = {
  actions: {
    submit: "Submit",
    submitAndClear: "Submit & Clear",
    backtoQueue: "Back To Queue",
  },
  title: {
    inspection: "Inspection",
    receiptNumber: "Receipt Number",
  },
  leftMenu: {
    receiptNumber: "Receipt #",
    numberOfCoolers: "# of coolers",
    cocAndOCRData: "COC & OCR Data",
    cocFiles: "COC Files",
    noneAttached: "None Attached",
    attached: "attached",
    ocrData: "OCR Data",
    totalSampleIDs: "Total Sample IDs",
    applyBinToAll: "Apply Bin to All",
    observations: "Observations",
    receivingDetails: "Receiving Details",
    issue: "Issue",
    comments: "Comments",
    save: "Save",
  },
  table: {
    column: {
      number: "No.",
      cocNumber: "COC #",
      cocLine: "COC Line #",
      sampleID: "Sample ID",
      matrix: "Matrix",
      exp: "Exp.",
      act: "Act.",
      sampleDate: "Sample Date",
      sampleTime: "Sample Time",
      numberOfParts: "# of Parts",
    },
    row: {
      number: "No.",
      bottleType: "Bottle Type",
      numberOfBottles: "# of Bottles",
      other: "Other",
      binNumber: "Bin #",
      delete: "Delete",
    },
    menu: {
      open: "Open",
      statusChange: "Status Change",
      delete: "Delete",
    },
  },
  modal: {
    observations: {
      editObservations: "Edit Observations",
      editInstructions:
        "Some comments may have been automatically generated. You can edit or add to the comments before submitting.",
      addInstructions:
        "Please select an issue and add the necessary comments. Only one record per issue type is allowed.",
      issue: "Issue",
      duplicateIssue: "Issue already exists for this Receipt #",
      comment: "Comment",
      edit: "Edit",
      delete: "Delete",
      close: "Close",
      addObservation: "Add Observation",
      add: "Add",
      anObservation: "an Observation",
      saveObservation: "Save Observation",
      issueDescription: {
        custodySealNotIntact: "Custody seal NOT intact",
        temperatureGreaterThan: "Temp > 10 degrees",
        brokenLidsBottles: "Broken Bottle in Transit",
        noCOC: "No C of C",
        incompleteCOC: "C of C information incomplete",
        notSignedDatedCOC: "C of C not signed/dated",
        nonCurrentCOC: "Non-current C of C",
        listedOnCOCNotReceived:
          "Bottles/Samples listed on COC but not received",
        receivedNotListed: "Bottles/Samples received but not listed on COC",
        analysisReqIncomplete: "Analysis Requirements absent/unclear",
        incorrectLabelling: "Labelling issue (missing/incorrect)",
        greaterThan5DaysSinceSamplingDate:
          "Samples received >5 days after sampling",
        receivedPastHoldTime: "Samples received past hold time",
        wrongBottle: "Wrong bottle(s) used",
        headspaceWrongPreservative: "Incorrect preservation or headspace",
        insufficientNumOfBottles: "Insufficient # of bottles (with no flag)",
        incorrectTaskOrder: "Incorrect Task Order #",
        insSampVolForRequestedTest:
          "Insufficient Sample Volume for Requested Test",
        sampNeedingFiltWasPreserved:
          "Sample Requiring Filtration Received Preserved",
        capMissingBroken: "Cap Missing or Broken",
        sampleContainerEmpty: "Sample Container Received Empty",
        sampleFrozen: "Sample Received Frozen",
        sedimentInGOC: "Sediment > 1 CM in Groundwater Org. Container",
        specialAnalysis: "Special Analysis Required",
      },
      issueComment: {
        temperatureGreaterThan: "Temperature above 10 degrees for",
        temperatureGreaterThanNoCoolant:
          "Temperature above 10 degrees and no coolant present for",
        custodySealNotIntact: "Custody seal present but not intact for",
        cooler: "cooler",
      },
    },
    applySampleID: {
      applySampleIdHeader: "Apply Sample ID Naming",
      sampleIDRoot: "Sample ID Root",
      suffix: "Suffix",
      startingNumber: "Starting Number",
      startingLetter: "Starting Letter",
      startingAlphanumeric: "Starting Alphanumeric",
      applyToAllSamples: "Apply to all samples",
      applyToSelectSamples: "Apply to select samples",
      applyToAll: "Apply to All",
      applyToSelected: "Apply to Selected",
      applyAndClose: "Apply and Close",
      none: "None",
      numbers: "Numbers",
      letters: "Letters",
      addDepths: "Add Depths",
      startDepth: "Start Depth",
      endDepth: "End Depth",
      addOn: "Add On",
    },
    applyBinNumber: {
      applyBinNumberHeader: "Apply Bin #",
      blankBinMessage:
        "This will remove all the bin # for this bottle type. Are you sure you want to proceed?",
      blankBinAndBottleMessage:
        "This will remove all the bin # for each selected sample. Are you sure you want to proceed?",
    },
    applyColumnData: {
      columnDataHeader: "Apply Column Data",
      column: "Column",
      data: "Data",
      applyToAllSamples: "Apply to all samples",
      applyToSelectSamples: "Apply to select samples",
      applyToAll: "Apply to All",
      applyToSelected: "Apply to Selected",
      applyAndClose: "Apply and Close",
      addColumn: "Add Column",
      cocNo: "CoC No.",
    },
    clearSelectColumns: {
      clearColumnData: "Clear Column Data",
      cocNumber: "COC #",
      cocLineNumber: "COC Line #",
      sampleID: "Sample ID",
      matrix: "Matrix",
      exp: "Exp.",
      act: "Act.",
      sampleDate: "Sample Date",
      sampleTime: "Sample Time",
      deleteData: "Delete Data",
      warningMessage: "This data will be lost forever. Are you sure?",
    },
    addMultipleParts: {
      addMultiplePartsHeader: "Add Multiple Parts",
      numberOfParts: "# of Parts",
      createParts: "Create Parts",
    },
    copyParts: {
      copyPartsToSamples: "Copy Parts to Samples",
      fromSample: "From Sample",
      toSamples: "To Samples",
      sampleID: "Sample ID",
      parts: "Parts",
      copyParts: "Copy Parts",
      backToCopyParts: "Back To Copy Parts",
      viewFullList: "View Full List",
      useSelectedSamples: "Use Selected Samples",
    },
    duplicateMultiple: {
      duplicateMultipleHeader: "Duplicate Multiple",
      numberOfCopies: "# of Copies",
      createCopies: "Create Copies",
    },
    confirm: {
      confirmClose:
        "Your changes will not be saved. Are you sure you want to continue?",
      confirmSubmit: "Confirm Submission?",
    },
    jobCreated: {
      jobCreatedHeader: "Job Successfully Created",
      jobNumber: "Job number",
      printSampleLabels: "Print Sample Labels",
      printJobLabels: "Print Job Labels",
      printRightJustifySampleLabels:
        "Print Sample Labels / W IDs Right Justified",
      noLabels: "Labels are not configured for printing.",
    },
  },
  menuItemsHeader: {
    applySampleIdNaming: "Apply Sample ID Naming",
    applyColumnData: "Apply Column Data",
    applyBinNumber: "Apply Bin #",
    clearSelectColumns: "Clear Select Columns",
  },
  menuItems: {
    editSample: "Edit Sample",
    addMultipleParts: "Add Multiple Parts",
    copyParts: "Copy Parts",
    copyPartsToAll: "Copy Parts to All",
    duplicate: "Duplicate",
    duplicateMultiple: "Duplicate Multiple",
    delete: "Delete",
  },
  errors: {
    cocNumber: "COC #",
    sampleID: "Sample ID",
    matrix: "Matrix",
    sampleDate: "Sample Date must be earlier than Received Date",
    sampleTime: "Sample Time cannot be in the future",
    bottleType: "Bottle Type",
    numberOfBottles: "# of Bottles",
  },
};

const F = {
  actions: {
    submit: "Soumettre",
    submitAndClear: "Soumettre et effacer",
    backtoQueue: "Réception d'échantillons",
  },
  title: {
    inspection: "L'inspection",
    receiptNumber: "No. de réception",
  },
  leftMenu: {
    receiptNumber: "No. de réception",
    numberOfCoolers: "Nbr. de glacières",
    cocAndOCRData: "Données CDR et ROC",
    cocFiles: "Fichiers CDR",
    noneAttached: "Aucun attaché",
    attached: "attachés",
    ocrData: "Charger les données OCR",
    totalSampleIDs: "Nbr. d'échantillons",
    applyBinToAll: "Appliquer # Bac sur toutes les parties",
    observations: "Observations",
    receivingDetails: "Détails du dossier",
    issue: "Problème",
    comments: "Commentaires",
    save: "Sauvegarder",
  },
  table: {
    column: {
      number: "No.",
      cocNumber: "No. de CDR ",
      cocLine: "Ligne de CDR",
      sampleID: "ID de l’échantillon",
      matrix: "Matrice",
      exp: "Att.",
      act: "Réel",
      sampleDate: "Date d'échantillonnage",
      sampleTime: "Heure d'échantillonnage",
      numberOfParts: "No. de parties",
    },
    row: {
      number: "No.",
      bottleType: "Type de bouteille",
      numberOfBottles: "No. des bouteilles",
      other: "Autre",
      binNumber: "Numéro de bac",
      delete: "Effacer",
    },
    menu: {
      open: "Open",
      statusChange: "Status Change",
      delete: "Delete",
    },
  },
  modal: {
    observations: {
      editObservations: "Modifier les observations",
      editInstructions:
        "Certains commentaires peuvent avoir été générés automatiquement. Vous pouvez modifier ou ajouter des commentaires avant de les soumettre.",
      addInstructions:
        "Veuillez sélectionner un problème et ajouter les commentaires nécessaires. Un seul enregistrement par type de problème est autorisé.",
      issue: "Problème",
      duplicateIssue: "Le problème existe déjà pour ce numéro de reçu",
      comment: "Commentaire",
      edit: "Modifier",
      delete: "Effacer",
      close: "Fermer",
      addObservation: "Ajouter une observation",
      add: "Ajouter",
      anObservation: "une observation",
      saveObservation: "Enregistrer l'observation",
      issueDescription: {
        custodySealNotIntact: "Le scellé sur la glacière est endommagé",
        temperatureGreaterThan: "Température >10°C",
        brokenLidsBottles: "Bouteille(s) brisée(s) lors du transport",
        noCOC: "Pas de chaîne de responsabilité avec les bouteilles",
        incompleteCOC: "Chaîne de responsabilité incomplète",
        notSignedDatedCOC:
          "Chaîne de responsabilité non signée et/ou non datée",
        nonCurrentCOC:
          "La version de la chaîne de responsabilité n'est pas à jour",
        listedOnCOCNotReceived:
          "Bouteilles listées sur la chaîne de responsabilité mais manquantes dans l'envoi",
        receivedNotListed:
          "Bouteilles dans l'envoi non listées sur la chaîne de responsabilité",
        analysisReqIncomplete:
          "Analyses non spécifiées ou pas claires sur la chaîne de responsabilité",
        incorrectLabelling:
          "Problème d'étiquetage  des contenants d'échantillonnage",
        greaterThan5DaysSinceSamplingDate:
          "Échantillon reçu > 5 jours après le prélèvement",
        receivedPastHoldTime: "Échantillon reçu après le délai de conservation",
        wrongBottle: "Utilisation des mauvaises bouteilles de prélèvement",
        headspaceWrongPreservative:
          "Préservatif non approprié ou présence d'un espace d'air",
        insufficientNumOfBottles: "Quantité insuffisante de bouteilles",
        incorrectTaskOrder: "Mauvais numéro d'engagement",
        insSampVolForRequestedTest:
          "Quantité insuffisante d'échantillon pour l'analyse demandée",
        sampNeedingFiltWasPreserved:
          "Échantillon reçus préservés, mais le client demande une filtration avant l'analyse",
        capMissingBroken:
          "Bouchon brisé ou manquant, contamination possible de l’échantillon",
        sampleContainerEmpty: "Contenant d'échantillon vide reçu",
        sampleFrozen: "Échantillon reçu congélé (< 0 C)",
        sedimentInGOC:
          "Présence du sédiment (>1cm) dans le contenant pour une eau souterraine organique",
        specialAnalysis: "Analyse spéciale requise",
      },
      issueComment: {
        temperatureGreaterThan: "Température supérieure à 10°C pour",
        temperatureGreaterThanNoCoolant:
          "Température supérieure à 10°C et absence de réfrigérant pour",
        custodySealNotIntact: "Scellé légal présent mais non intact pour",
        cooler: "glacière",
      },
    },
    applySampleID: {
      applySampleIdHeader: "Appliquer l'ID d'échantillon",
      sampleIDRoot: "Racine d'ID d'échantillon",
      suffix: "Suffixe",
      startingNumber: "Numéro de départ",
      startingLetter: "Lettre de départ",
      startingAlphanumeric: "Alphanumérique de départ",
      applyToAllSamples: "Appliquer à tous les échantillons",
      applyToSelectSamples: "Appliquer pour sélectionner des échantillons",
      applyToAll: "S'applique à tous",
      applyToSelected: "Appliquer à la sélection",
      applyAndClose: "Appliquer et Fermer",
      none: "Aucun",
      numbers: "Des numéros",
      letters: "Des lettres",
      addDepths: "Ajouter les profondeurs",
      startDepth: "Profondeur de départ",
      endDepth: "Profondeur de fin",
      addOn: "Ajout",
    },
    applyBinNumber: {
      applyBinNumberHeader: "Appliquer # Bac",
      blankBinMessage:
        "Cela supprimera tous les # bacs pour ce type de bouteille. Voulez-vous vraiment continuer ?",
      blankBinAndBottleMessage:
        "Cela supprimera tous les # bacs pour chaque échantillon sélectionné. Voulez-vous vraiment continuer?",
    },
    applyColumnData: {
      columnDataHeader: "Appliquer les données de colonne",
      column: "Colonne",
      data: "Les données",
      applyToAllSamples: "Appliquer à tous les échantillons",
      applyToSelectSamples: "Appliquer pour sélectionner des échantillons",
      applyToAll: "S'applique à tous",
      applyToSelected: "Appliquer à la sélection",
      applyAndClose: "Appliquer et Fermer",
      addColumn: "Ajouter une colonne",
      cocNo: "No. de CDR",
    },
    clearSelectColumns: {
      clearColumnData: " Effacer les données de la colonne",
      cocNumber: "No. de CDR ",
      cocLine: "Ligne de CDR",
      sampleID: "ID de l’échantillon",
      matrix: "Matrice",
      exp: "Att.",
      act: "Réel",
      sampleDate: "Date d'échantillonnage",
      sampleTime: "Heure d'échantillonnage",
      deleteData: "Suprimmer les données",
      warningMessage: "Ces données seront perdues à jamais. Vous êtes sûr ?",
    },
    addMultipleParts: {
      addMultiplePartsHeader: "Ajouter plusieurs parties",
      numberOfParts: "No. de parties",
      createParts: "Créer des parties",
    },
    copyParts: {
      copyPartsToSamples: "Copier des parties à un autre échantillon",
      fromSample: "à partir de l'échantillon",
      toSamples: "à l'échantillon",
      sampleID: "ID de l’échantillon",
      parts: "Parties",
      copyParts: "Copier les parties",
      backToCopyParts: "Retour à copie les parties",
      viewFullList: "Voir la liste complète",
      useSelectedSamples: "Utiliser les échantillons sélectionnés",
    },
    duplicateMultiple: {
      duplicateMultipleHeader: "Dupliquer Multiples",
      numberOfCopies: "No. des copies",
      createCopies: "Créer des copies",
    },
    confirm: {
      confirmClose:
        "Vos modifications ne seront pas enregistrées. Êtes-vous sûr de vouloir continuer ?",
      confirmSubmit: "Confirmer la Sousmission?",
    },
    jobCreated: {
      jobCreatedHeader: "Dossier créé avec succès",
      jobNumber: "No. de Dossier",
      printSampleLabels: "Imprimer les étiquettes d’échantillons",
      printJobLabels: "Imprimer les étiquettes de dossier",
      printRightJustifySampleLabels:
        "Imprimer les étiquettes d’échantillons aligner à droite",
      noLabels: "Les étiquettes ne sont pas configurées pour l'impression.",
    },
  },
  menuItemsHeader: {
    applySampleIdNaming: "Appliquer l'ID d'échantillon",
    applyColumnData: "Appliquer les données de colonne",
    applyBinNumber: "Appliquer # Bac",
    clearSelectColumns: "Effacer les colonnes sélectionnées",
  },
  menuItems: {
    editSample: "Modifier l'échantillon",
    addMultipleParts: "Ajouter plusieurs parties",
    copyParts: "Copier des parties",
    copyPartsToAll: "Copier des parties à tous",
    duplicate: "Dupliquer",
    duplicateMultiple: "Dupliquer Multiples",
    delete: "Effacer",
  },
  errors: {
    cocNumber: "No. de CDR ",
    sampleID: "ID de l’échantillon",
    matrix: "Matrice",
    sampleDate:
      "La date d'échantillon doit être antérieure à la date de réception",
    sampleTime: "L'heure d’échantillonnage ne peut être dans le futur",
    bottleType: "Type de bouteille",
    numberOfBottles: "No. des bouteilles",
  },
};

const resources = { E, F };

export default resources;
