import { Suspense, useEffect } from "react";

import _ from "lodash";

import { Navigate, Route, Routes } from "react-router-dom";
import AuthGuard from "src/components/AuthGuard";
import LoadingScreen from "src/components/LoadingScreen";
import useAuth from "src/hooks/useAuth";
import DashboardLayout from "src/layouts/DashboardLayout";

import type { IRoute } from "src/types/IRoute";
import type { ISection } from "src/types/ISection";
import i18n from "./i18n";

const findPathAccess = (sections: ISection[], path: string): boolean => {
  let access = true;

  const section = _.find(sections, function (x) {
    const item = _.find(x.items, function (y) {
      return y.href.toLowerCase() === path.toLowerCase();
    });
    return item !== undefined;
  });

  if (section !== undefined) {
    access = _.find(section.items, function (y) {
      return y.href.toLowerCase() === path.toLowerCase();
    }).hasAccess;
  }

  return access;
};

const renderRoutes = (
  navigateTo: string,
  noAccessComponent: any,
  sections: ISection[],
  routes: IRoute[]
): JSX.Element[] =>
  routes.map((route, i) => {
    const pathAccess = findPathAccess(sections, route.path.toString());
    const Component = pathAccess ? route.component : noAccessComponent;
    const routeKey = `route_${i}`;

    return (
      <Route
        key={routeKey}
        path={route.path}
        element={
          <AuthGuard navigateTo={navigateTo}>
            {route.routes ? (
              renderRoutes(
                navigateTo,
                noAccessComponent,
                sections,
                route.routes
              )
            ) : (
              <Component />
            )}
          </AuthGuard>
        }
      />
    );
  });

const Content = (): JSX.Element => {
  const { user, navigationContent } = useAuth();

  useEffect(() => {
    i18n.changeLanguage(user.UserLanguage);
  }, [user.UserLanguage]);

  const {
    redirectTo: navigateTo,
    noAccessRedirectTo,
    sections,
    routes,
  } = navigationContent;
  let route = _.find(
    routes,
    (x) => x.path?.toString().toLowerCase() === noAccessRedirectTo.toLowerCase()
  );

  if (route === undefined) {
    route = _.find(routes, function (x) {
      return x.path.toString().toLowerCase() === navigateTo?.toLowerCase();
    });
  }

  const noAccessComponent = route.component;

  return (
    <DashboardLayout data-display-name="Content">
      <Suspense fallback={<LoadingScreen />}>
        <Routes>
          {renderRoutes(navigateTo, noAccessComponent, sections, routes)}
          <Route path="/" element={<Navigate replace to={navigateTo} />} />
        </Routes>
      </Suspense>
    </DashboardLayout>
  );
};

export default Content;
