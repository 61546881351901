const globalStyles = {
  "*": {
    boxSizing: "border-box",
    margin: 0,
    padding: 0,
  },
  html: {
    WebkitFontSmoothing: "antialiased",
    MozOsxFontSmoothing: "grayscale",
    height: "100%",
    width: "100%",
  },
  body: {
    height: "100%",
    width: "100%",
    overflow: "hidden !important",
  },
  "#root": {
    height: "100%",
    width: "100%",
  },
};

export default globalStyles;
