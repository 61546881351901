export const APP_VERSION = "0.1.0";

export const BV_APP_NAME = "BV AC";

export const BV_CSS_PREFIX = "BV";

export const DATE_FORMAT = "YYYY-MM-DD";

export const TIME_FORMAT = "HH:mm";

export const BOTTLES_ARRAY = [1, 2, 3, 4, 5];
