import React, { lazy } from "react";
import { Navigate } from "react-router-dom";
import type { IRoute } from "src/types/IRoute";

const routes: IRoute[] = [
  {
    path: "/404",
    component: lazy(() => import("src/errors/NotFoundView")),
  },
  {
    path: "/noAccess",
    component: lazy(() => import("src/errors/NoAccessView")),
  },
  {
    path: "/terms",
    component: lazy(() => import("src/system/TermsView")),
  },
  {
    path: "/privacy",
    component: lazy(() => import("src/system/PrivacyView")),
  },
  {
    path: "/",
    component: () => <Navigate to="/samples" />,
  },
  {
    path: "/login",
    component: () => <Navigate to="/samples" />,
  },
  {
    path: "/logout",
    component: lazy(
      () => import("src/components/auth/NotAuthenticatedContent")
    ),
  },
  {
    path: "*",
    component: () => <Navigate to="/404" />,
  },
];

export default routes;
