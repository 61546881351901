import { lazy } from "react";
import commonRoutes from "src/CommonRoutes";
import type { IRoute } from "src/types/IRoute";

const routes: IRoute[] = [
  {
    path: "/samples",
    component: lazy(() => import("src/views/sampleReception")),
  },
  {
    path: "/samples/new",
    component: lazy(() => import("src/views/cooler")),
  },
  {
    path: "/samples/:ReceiptQueueId/edit",
    component: lazy(() => import("src/views/cooler")),
  },
  {
    path: "/samples/:ReceiptQueueId/inspect",
    component: lazy(() => import("src/views/inspection")),
  },
];

const combinedRoutes = [...routes, ...commonRoutes];

export default combinedRoutes;
