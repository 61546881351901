import { AppBar, Grid, Toolbar } from "@mui/material";
import clsx from "clsx";
import { useState, type FC } from "react";
import appConfiguration from "src/appConfiguration";
import UserProfileCard from "src/components/UserProfileCard";
import useClasses from "src/hooks/useClasses";
import MultiSelectBar, { MultiSelectEvent } from "./components/MultiSelectBar";

interface Props {
  className?: string;
  setOpen?: () => void;
}

const styles = ({ theme }) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    backgroundColor: theme.palette.secondary.main,
    borderBottom: `1px solid #E0E0E0`,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
    minHeight: 64,
    width: "100%",
    paddingLeft: 6,
  },
});

const TopBar: FC<Props> = ({ className, setOpen, ...rest }: Props) => {
  const classes: any = useClasses(styles);
  const [selection, setSelection] = useState([]);
  const showMultiSelect = selection?.length > 0;
  const RightTopBar = appConfiguration.use().renderTopBar;

  const handleSelect = (_, selection) => {
    setSelection(selection);
  };

  const handleCloseMultiSelect = () => {
    setSelection([]);
    PubSub.publish(MultiSelectEvent.CANCEL);
  };

  return (
    <AppBar
      className={clsx(classes.root, className)}
      data-display-name="TopBar"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <MultiSelectBar
          selection={selection}
          onSelect={handleSelect}
          onClose={handleCloseMultiSelect}
        />
        {!showMultiSelect && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {RightTopBar && <RightTopBar />}
              <UserProfileCard />
            </Grid>
          </Grid>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
