import common from "./common";
import queue from "./queue";
import coolerEntry from "./cooler-entry";
import inspection from "./inspection";

const values = {
  E: {
    common: common.E,
    queue: queue.E,
    coolerEntry: coolerEntry.E,
    inspection: inspection.E,
  },
  F: {
    common: common.F,
    queue: queue.F,
    coolerEntry: coolerEntry.F,
    inspection: inspection.F,
  },
};

export default values;
