import { AxiosResponse } from "axios";
import { IResponseData } from "../types/IResponseData";
import { IPager } from "../types/IPager";
import { axiosInstance as axios } from "./axios";

export const api = {
  get(
    url: string,
    params: any = null,
    paging: IPager = null
  ): Promise<AxiosResponse<IResponseData>> {
    let args = {};

    if (params) {
      args = { ...args, ...params };
    }

    if (paging) {
      args = { ...args, ...paging };
    }

    const terms = [];
    Object.entries(args).forEach((x) => {
      const [key, value] = x;

      terms.push(`${key}=${value}`);
    });

    let query = "";

    if (terms.length) {
      query = `?${terms.join("&")}`;
    }

    return axios.get<IResponseData>(url + query);
  },

  put<t>(url: string, body: any): Promise<AxiosResponse<t>> {
    return axios.put<t>(url, body);
  },

  post(url: string, body: any): Promise<AxiosResponse<IResponseData>> {
    return axios.post<IResponseData>(url, body);
  },
};
