import { useReducer } from "react";
import { Action, ACTION_TYPES } from "./actions";
import reducer from "./reducer";
import services from "./services";
import { AuthContextValue, initialAuthState } from "./types";

interface IState {
  state: AuthContextValue;
  dispatch?: (value: Action) => void;
}

function useAuthState(): IState {
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const login = async (
    email: string,
    password: string,
    region: string
  ): Promise<void> => {
    await services
      .login(email, password, region)
      .then((user) => {
        services.getNavigationContent().then((navigationContent) => {
          dispatch({
            type: ACTION_TYPES.LOGIN,
            payload: {
              user,
              navigationContent,
            },
          });
        });
      })
      .catch((error) => {
        services.onError(error);
      });
  };

  const logout = async (): Promise<void> => {
    services.logout().catch((err) => {
      console.error(err);
    });
    dispatch({ type: ACTION_TYPES.LOGOUT });
  };

  return {
    state: { ...state, login, logout },
    dispatch,
  };
}

export default useAuthState;
