import { Close } from "@mui/icons-material";
import {
  Button,
  Container,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import PubSub from "pubsub-js";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

export enum MultiSelectEvent {
  SET_BUTTONS = "SET_BUTTONS",
  SET_SELECTION = "SET_SELECTION",
  CANCEL = "CANCEL",
}

type ButtonData = {
  label: string;
  icon: any;
  onClick: () => void;
};

interface Props {
  selection: any[];
  onSelect: (_, selection: any[]) => void;
  onClose: () => void;
}

const MultiSelectBar = ({ selection, onSelect, onClose }: Props) => {
  const { t } = useTranslation();
  const [buttons, setButtons] = useState<ButtonData[]>([]);

  useEffect(() => {
    const selectToken = PubSub.subscribe(
      MultiSelectEvent.SET_SELECTION,
      onSelect
    );
    const buttonToken = PubSub.subscribe(
      MultiSelectEvent.SET_BUTTONS,
      handleSetButtons
    );

    return () => {
      PubSub.unsubscribe(selectToken);
      PubSub.unsubscribe(buttonToken);
    };
  }, [onSelect]);

  const handleSetButtons = (_, buttonsData: ButtonData[]) => {
    setButtons(buttonsData);
  };

  if (selection.length === 0) return null;

  return (
    <Container maxWidth="xl">
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" spacing={1}>
          <IconButton onClick={onClose} sx={{ color: "#fff" }}>
            <Close />
          </IconButton>
          <Typography>
            {selection?.length} {t("common:selected")}
          </Typography>
        </Stack>
        <Stack direction="row" spacing={2}>
          {buttons.map(({ label, icon, onClick }) => (
            <Button variant="contained" startIcon={icon} onClick={onClick}>
              {label}
            </Button>
          ))}
        </Stack>
      </Stack>
    </Container>
  );
};

export default MultiSelectBar;
