import { createRoot } from "react-dom/client";

import App from "./App";
import appConfiguration from "./appConfiguration";
import { AuthContext, AuthProvider } from "./contexts/AuthContext";
import TopBarRight from "./components/TopBarRight";
import "./index.css";
import { getParameterValue } from "./utils";

appConfiguration.init({
  authContext: AuthContext,
  authContextProvider: AuthProvider,
  renderTopBar: TopBarRight,
});

window.APP_NAME = getParameterValue("app");
window.VIEW_CODE = getParameterValue("vc");
window.APP_NAME = "container"; // Remove this line when appLauncher is implemented
window.VIEW_CODE = "NavigationBar"; // Remove this line when appLauncher is implemented
window.API_URL = "https://testingweb4.bvna.com/MISWebAPI/api";

const container = document.getElementById("root");
const root = createRoot(container);
root.render(<App />);
