type Directions = "ltr" | "rtl";

interface Settings {
  direction?: Directions;
  responsiveFontSizes?: boolean;
  theme?: string;
  rememberMe?: string;
  debug?: boolean;
  region?: string;
  wsId?: number;
  wsLabel?: string;
  setupDate?: string;
  activities?: string[];
  selectedActivities?: string[];
}

export const restoreSettings = (): Settings | null => {
  let settings = {
    rememberMe: null,
    direction: "ltr",
  };

  try {
    const storedData: string | null = window.localStorage.getItem("settings");

    if (storedData) {
      settings = { ...settings, ...(JSON.parse(storedData) as Settings) };
      //document.dir = settings.direction;
    }
  } catch (err) {
    console.error(err);
  }

  return settings as Settings;
};

export const storeSettings = (settings: Settings = {}): void => {
  const currentSettings = restoreSettings();
  const mergedSettings = { ...(currentSettings || {}), ...settings };
  window.localStorage.setItem("settings", JSON.stringify(mergedSettings));
};
