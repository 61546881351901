import { Box } from "@mui/material";
import Account from "./Account";

interface TopBarProps {
  searchPath?: string;
}

const TopBarRight = ({
  searchPath = "advanceSearch",
}: TopBarProps): JSX.Element => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
      }}
    >
      <Box ml={2}>
        <Account />
      </Box>
    </Box>
  );
};

export default TopBarRight;
