import { FC, useState } from "react";

import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";

import { Check, Close, Visibility, VisibilityOff } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { axiosInstance } from "src/utils";
import { snackActions } from "src/utils/SnackbarUtils";
import { useProfile } from "../../contexts/ProfileContext";

interface Props {
  [key: string]: any;
}

const ChangePasswordForm: FC<Props> = () => {
  const { t } = useTranslation();
  const { dispatch } = useProfile();
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    confirm: false,
  });

  const handleClickShowPassword = (field): void => {
    if (field === "current") {
      setShowPassword((prev) => ({ ...prev, current: !prev.current }));
    }
    if (field === "new") {
      setShowPassword((prev) => ({ ...prev, new: !prev.new }));
    }
    if (field === "confirm") {
      setShowPassword((prev) => ({ ...prev, confirm: !prev.confirm }));
    }
  };

  const handleMouseDownPassword = (event): void => {
    event.preventDefault();
  };

  const VisibilityIcon = ({ field }) => {
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={() => handleClickShowPassword(field)}
          onMouseDown={handleMouseDownPassword}
          size="large"
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    );
  };

  enum PasswordValidation {
    LENGTH = "length",
    LOWERCASE = "lowercase",
    UPPERCASE = "uppercase",
    NUMBER = "number",
    SPECIAL = "special",
  }

  const validate = (values) => {
    const { CurrentPassword, NewPassword } = values;
    const errors = {} as any;
    const allowedSpecialChars = /[!@$%&*?]/;

    if (!CurrentPassword) {
      errors.currentPassword = "Please enter your current password";
    }
    if (!NewPassword) {
      errors.newPassword = "Please enter your new password";
    }
    if (NewPassword.length < 8 || NewPassword.length > 15) {
      errors.length = PasswordValidation.LENGTH;
    }
    if (!/^(?=.*[a-z])/.test(NewPassword)) {
      errors.lowerCase = PasswordValidation.LOWERCASE;
    }
    if (!/^(?=.*[A-Z])/.test(NewPassword)) {
      errors.upperCase = PasswordValidation.UPPERCASE;
    }
    if (!/^(?=.*[0-9])/.test(NewPassword)) {
      errors.number = PasswordValidation.NUMBER;
    }
    if (
      !/^[a-zA-Z0-9!@$%&*?]+$/.test(NewPassword) ||
      !allowedSpecialChars.test(NewPassword)
    ) {
      errors.special = PasswordValidation.SPECIAL;
    }

    return errors;
  };

  return (
    <Formik
      validateOnChange
      enableReinitialize
      initialValues={{
        CurrentPassword: "",
        NewPassword: "",
        ConfirmPassword: "",
      }}
      initialErrors={{
        currentPassword: "",
        newPassword: "",
        length: PasswordValidation.LENGTH,
        lowerCase: PasswordValidation.LOWERCASE,
        upperCase: PasswordValidation.UPPERCASE,
        number: PasswordValidation.NUMBER,
        special: PasswordValidation.SPECIAL,
      }}
      validate={validate}
      onSubmit={async (values, actions) => {
        try {
          await axiosInstance.put(`/User/UpdatePassword`, {
            CurrentPassword: values.CurrentPassword,
            NewPassword: values.NewPassword,
          });
          actions.setStatus({ success: true });
          snackActions.success("Successfully updated password");
          dispatch({ type: "SET_CLOSED" });
        } catch (err) {
          snackActions.error("Failed to update password");
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        touched,
        values,
      }: any) => {
        const { currentPassword, ...newPasswordErrors } = errors;

        return (
          <Form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ mt: 2 }}>
                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", color: "gray" }}
                >
                  {t("common:changePasswordScreen:changePasswordHeader")}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t("common:changePasswordScreen:temporaryPassword")}
                  name="CurrentPassword"
                  value={values.CurrentPassword}
                  onBlur={handleBlur}
                  onChange={handleChange}
                  variant="outlined"
                  type={showPassword.current ? "text" : "password"}
                  InputProps={{
                    endAdornment: <VisibilityIcon field="current" />,
                  }}
                  error={
                    touched.CurrentPassword && Boolean(errors?.currentPassword)
                  }
                  helperText={
                    touched.CurrentPassword && errors?.currentPassword
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label={t("common:changePasswordScreen:newPassword")}
                  name="NewPassword"
                  onBlur={handleBlur}
                  variant="outlined"
                  value={values.NewPassword}
                  onChange={handleChange("NewPassword")}
                  type={showPassword.new ? "text" : "password"}
                  InputProps={{
                    endAdornment: <VisibilityIcon field="new" />,
                  }}
                />

                <Typography
                  variant="h4"
                  sx={{ fontWeight: "bold", color: "gray", mt: 2 }}
                >
                  {t("common:changePasswordScreen:passwordRequirements")}
                </Typography>

                <Grid container spacing={2} sx={{ ml: 1, mt: 1 }}>
                  {errors?.length ? (
                    <Close sx={{ color: "red" }} />
                  ) : (
                    <Check sx={{ color: "green" }} />
                  )}
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {t("common:changePasswordScreen:charLimit")}
                  </Typography>
                </Grid>
                <Grid container spacing={2} sx={{ ml: 1, mt: 1 }}>
                  {errors?.upperCase ? (
                    <Close sx={{ color: "red" }} />
                  ) : (
                    <Check sx={{ color: "green" }} />
                  )}
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {t("common:changePasswordScreen:upperCase")}
                  </Typography>
                </Grid>
                <Grid container spacing={2} sx={{ ml: 1, mt: 1 }}>
                  {errors?.lowerCase ? (
                    <Close sx={{ color: "red" }} />
                  ) : (
                    <Check sx={{ color: "green" }} />
                  )}
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {t("common:changePasswordScreen:lowerCase")}
                  </Typography>
                </Grid>
                <Grid container spacing={2} sx={{ ml: 1, mt: 1 }}>
                  {errors?.number ? (
                    <Close sx={{ color: "red" }} />
                  ) : (
                    <Check sx={{ color: "green" }} />
                  )}
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {t("common:changePasswordScreen:number")}
                  </Typography>
                </Grid>
                <Grid container spacing={2} sx={{ ml: 1, mt: 1 }}>
                  {errors?.special ? (
                    <Close sx={{ color: "red" }} />
                  ) : (
                    <Check sx={{ color: "green" }} />
                  )}
                  <Typography variant="body1" sx={{ ml: 1 }}>
                    {t("common:changePasswordScreen:specialChar")}
                  </Typography>
                </Grid>

                <Grid
                  container
                  sx={{ display: "flex", alignItems: "center", mt: 2 }}
                >
                  <Grid item xs={3.8}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      id="save-password"
                      disabled={Object.keys(newPasswordErrors).length !== 0}
                    >
                      {t("common:changePasswordScreen:savePassword")}
                    </Button>
                  </Grid>
                  {Object.keys(newPasswordErrors).length !== 0 && (
                    <>
                      <Grid item xs={0.35}>
                        <Close sx={{ color: "red" }} />
                      </Grid>
                      <Grid item xs={7.75}>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                            ml: 1,
                          }}
                        >
                          {t("common:changePasswordScreen:newPasswordReq")}
                        </Typography>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChangePasswordForm;
