import { Box, Typography } from "@mui/material";
import React from "react";

const Terms = (): JSX.Element => {
  return (
    <div data-display-name="Terms">
      <Typography variant="h1" color="secondary">
        Terms and Conditions of Use
      </Typography>
      <Box mt={3}>
        <Typography variant="body1">
          <strong>Sept 30, 2020 Version 1.0</strong>
        </Typography>
      </Box>
      <Box mt={3}>
        <Typography variant="body1">
          Bureau Veritas (herein “BV”), is pleased to offer our customers secure
          online access to reports and other on-line tools via the BVue. By
          using the BVue, users (including super users) hereby acknowledge that
          they have read, understand, and accept the following terms and
          conditions of use:
        </Typography>
      </Box>
      <Box mt={3}>
        <Typography variant="body1">
          User agrees and certifies that they have the authority and permission
          of the data owner to view the reports. If the user should not have
          access or does not have permission to view the reports, the user must
          immediately stop using the BVue and notify BV.
        </Typography>
      </Box>
      <Box mt={3}>
        <Typography variant="body1">
          Users are responsible for maintaining the integrity, accuracy and
          security of usernames and passwords. If it is suspected that a
          username or password has been compromised, the user agrees to
          de-activate the account or change the password immediately and to
          notify BV.
        </Typography>
      </Box>
      <Box mt={3}>
        <Typography variant="body1">
          The sharing of usernames and passwords across multiple users is
          prohibited.
        </Typography>
      </Box>
      <Box mt={3}>
        <Typography variant="body1">
          If there are any personnel changes within the customer’s organization
          that impacts a user’s access rights, it is the client’s responsibility
          to contact BV and notify us of those changes so as to ensure future
          security of your data.
        </Typography>
      </Box>
      <Box mt={3}>
        <Typography variant="body1">
          Demonstrating and showcasing the BVue to other service providers that
          are or could be competitors of BV is strictly prohibited.
        </Typography>
      </Box>
      <Box mt={3}>
        <Typography variant="body1">
          Reports available via the BVue or other electronic data does not
          constitute or substitute the official reports provided
        </Typography>
      </Box>
      <Box mt={3}>
        <Typography variant="body1">
          BV is not liable for any damages directly or indirectly related to the
          use of the BVue or the use, interpretation, or application of the data
          provided.
        </Typography>
      </Box>
      <Box mt={3}>
        <Typography variant="body1">
          BV reserves the right to revoke access to the BVue at any time without
          notice.
        </Typography>
      </Box>
      <Box mt={3}>
        <Typography variant="body1">
          BV maintains the right to access a user’s BVue account.
        </Typography>
      </Box>
      <Box mt={3}>
        <Typography variant="body1">
          Terms and conditions of use are subject to change without notice.
        </Typography>
      </Box>
    </div>
  );
};

export default Terms;
