import React from "react";

import { Backdrop, CircularProgress, useTheme } from "@mui/material";

import NotAuthenticatedContent from "./components/auth/NotAuthenticatedContent";
import Content from "./Content";
import useAuth from "./hooks/useAuth";
import Root from "./Root";

const AppContent: React.FC = () => {
  const { user, isInitialized } = useAuth();
  const loading = !isInitialized;
  const theme = useTheme();

  if (loading) {
    return (
      <div data-display-name="App">
        <Backdrop
          open={loading}
          sx={{
            padding: "0px",
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: "#fff",
          }}
        >
          <CircularProgress color="secondary" />
        </Backdrop>
      </div>
    );
  }

  if (user) {
    return <Content />;
  }

  return <NotAuthenticatedContent />;
};

const App: React.FC = (): JSX.Element => {
  return (
    <Root>
      <AppContent />
    </Root>
  );
};

export default App;
