import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  Link,
  TextField,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { Formik } from "formik";
import type { FC } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
import ErrorPanel from "src/components/Forms/ErrorPanel";
import { Region } from "src/enums";
import useAuth from "src/hooks/useAuth";
import { restoreSettings, storeSettings, validationMessage } from "src/utils";
import * as Yup from "yup";

interface LoginProps {}

const Login: FC<LoginProps> = ({ ...rest }: LoginProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { login } = useAuth() as any;

  const handleClickShowPassword = (): void => {
    setShowPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (event): void => {
    event.preventDefault();
  };

  const regions = [Region.EASTERN, Region.WESTERN, Region.QUEBEC];
  const { t } = useTranslation();

  return (
    <div data-display-name="Login">
      <Typography variant="h3">
        <>{t("common:logInToBv")}</>
      </Typography>
      <Typography variant="body1">
        {t("common:enterYourInformation")}
      </Typography>
      <Formik
        validateOnChange={false}
        validateOnBlur={false}
        initialValues={{
          email: restoreSettings().rememberMe,
          password: "",
          region: "",
          rememberMe: Boolean(restoreSettings().rememberMe),
          bvOne: true,
          submit: null,
          usePanel: "",
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t("common:emailValidation"))
            .max(255)
            .nullable()
            .required(validationMessage(t("common:emailAddress"))),
          password: Yup.string()
            .max(255)
            .required(validationMessage(t("common:password"))),
          region: Yup.string()
            .max(255)
            .required(validationMessage(t("common:region"))),
        })}
        onSubmit={(values, { setErrors, setStatus, setSubmitting }): void => {
          login(values.email.trim(), values.password.trim(), values.region)
            .then((response) => {
              window.location.reload();
                storeSettings({ rememberMe: "" });
                if (values.rememberMe) {
                storeSettings({ rememberMe: values.email });
              }              
            })
            .catch((err) => {
              setStatus({ success: false });
              if (err?.message === undefined) {
                setErrors({
                  usePanel: "Y",
                  submit:
                    err?.status === 400
                      ? "Incorrect Email or Password. Please try again"
                      : "Something went wrong. Check you internet connection",
                });
              } else {
                setErrors({ usePanel: "Y", submit: err.message });
              }
              setSubmitting(false);
            });
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          touched,
          values,
        }): JSX.Element => (
          <form noValidate onSubmit={handleSubmit} {...rest}>
            <Box mt={2} mb={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.email && errors.email)}
                    fullWidth
                    helperText={touched.email && errors.email}
                    label={t("common:emailAddress")}
                    name="email"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    type="email"
                    value={values.email}
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    error={Boolean(touched.password && errors.password)}
                    fullWidth
                    helperText={touched.password && errors.password}
                    label={t("common:password")}
                    name="password"
                    value={values.password}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    variant="outlined"
                    type={showPassword ? "text" : "password"}
                    autoComplete="new-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            size="large"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth>
                    <Autocomplete
                      id="region-autocomplete"
                      options={regions}
                      value={values.region}
                      onChange={(e, value: any) => {
                        setFieldValue("region", value);
                      }}
                      autoHighlight
                      fullWidth
                      renderInput={(params) => {
                        return (
                          <TextField
                            {...params}
                            label={t("common:region")}
                            error={touched.region && Boolean(errors.region)}
                            helperText={touched.region && errors.region}
                          />
                        );
                      }}
                    />
                  </FormControl>
                </Grid>

                <Grid item xs={12}>
                  {errors?.usePanel === "Y" && (
                    <ErrorPanel errors={{ errors: errors?.submit }} />
                  )}
                  <Button
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    {t("common:login")}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </form>
        )}
      </Formik>
      <Box mt={2}>
        <Link
          component={RouterLink}
          to="/forgot"
          variant="caption"
          color="primary"
        >
          {t("common:resetPassword")}
        </Link>
      </Box>
    </div>
  );
};

export default Login;
