import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Button, Collapse, IconButton, ListItem } from "@mui/material";
import clsx from "clsx";
import type { FC, ReactNode } from "react";
import React, { useState } from "react";
import { NavLink as RouterLink } from "react-router-dom";
import useClasses from "src/hooks/useClasses";
import { usePrompt } from "src/hooks/usePrompt";

interface NavItemProps {
  children?: ReactNode;
  depth: number;
  href?: string;
  icon?: any;
  info?: any;
  open?: boolean;
  title: string;
  access?: boolean;
}

const styles = ({ theme }) => ({
  button: {
    color: theme.palette.secondary.main,
    padding: "10px 8px",
    justifyContent: "flex-start",
    textTransform: "none",
    letterSpacing: 0,
    width: "100%",
    fontWeight: 400,
    "&:active": {
      color: theme.palette.primary.main,
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
      },
      "& $icon": {
        color: theme.palette.primary.main,
      },
    },
  },
  buttonLeaf: {
    "&.depth-0": {
      "& $title": {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
  },
  icon: {
    display: "flex",
    alignItems: "center",
    marginRight: theme.spacing(1),
  },
  title: {
    marginRight: "auto",
  },
  active: {
    color: theme.palette.primary.main,
    "& $title": {
      fontWeight: theme.typography.fontWeightMedium,
    },
    "& $icon": {
      color: theme.palette.primary.main,
    },
  },
});

const NavItem: FC<NavItemProps> = ({
  children,
  depth,
  href,
  icon: Icon,
  info: Info,
  open: openProp = false,
  title,
  access,
  ...rest
}: NavItemProps) => {
  const classes: any = useClasses(styles);
  const [open, setOpen] = useState<boolean>(openProp);
  const { state, handleState } = usePrompt();

  const handleToggle = (e: React.SyntheticEvent, path: string): void => {
    setOpen((prevOpen) => !prevOpen);
    if (state.dirty) {
      e.preventDefault();
      if (path !== state.path) {
        handleState({ ...state, open: state.dirty, path });
      }
    }
  };

  let paddingLeft = 8;
  const hasAccess = access && access === true;
  const toPath = hasAccess ? href : "/noaccess";

  if (depth > 0) {
    paddingLeft = 32 + 8 * depth;
  }

  const style = { paddingLeft };

  if (children) {
    return (
      <ListItem
        sx={{
          display: "block",
          paddingTop: 0,
          paddingBottom: 0,
        }}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          className={clsx(classes.button, classes.buttonLeaf)}
          component={RouterLink}
          color="secondary"
          sx={{
            paddingLeft,
            "&.active": {
              color: "primary.main",
            },
          }}
          to={toPath}
          // onClick={(e: React.SyntheticEvent) => {
          //   e.preventDefault();
          //   handleToggle(e, toPath);
          // }}
          endIcon={
            open ? (
              <IconButton
                onClick={(e: React.SyntheticEvent) => {
                  e.preventDefault();
                  handleToggle(e, toPath);
                }}
                size="small"
              >
                <ExpandLessIcon />
              </IconButton>
            ) : (
              <IconButton
                onClick={(e: React.SyntheticEvent) => {
                  e.preventDefault();
                  handleToggle(e, toPath);
                }}
                size="small"
              >
                <ExpandMoreIcon />{" "}
              </IconButton>
            )
          }
        >
          {Icon && <Icon className={classes.icon} size="20" color="inherit" />}
          <span className={classes.title}>{title}</span>
          {/* {open ? <ExpandLessIcon /> : <ExpandMoreIcon />} */}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }

  return (
    <ListItem
      sx={{
        display: "flex",
        paddingTop: 0,
        paddingBottom: 0,
      }}
      data-display-name="NavItem"
      disableGutters
      key={title}
      {...rest}
    >
      <Button
        color="secondary"
        disabled={!hasAccess}
        sx={{
          "&.active": {
            color: "primary.main",
          },
        }}
        className={clsx(classes.button, classes.buttonLeaf, `depth-${depth}`)}
        component={RouterLink}
        style={style}
        to={toPath}
        onClick={(e: React.SyntheticEvent) => handleToggle(e, toPath)}
      >
        {Icon && <Icon className={classes.icon} size="20" color="inherit" />}
        <span className={classes.title}>{title}</span>
        {Info && <Info />}
      </Button>
    </ListItem>
  );
};

export default NavItem;
